const toServer = (date: Date): string => {
    return date.toISOString().split('.')[0];
}

const fromServer = (date: string): Date => {
    return new Date(date);
}


export default{
    toServer,
    fromServer
}