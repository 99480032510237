import "./TableSatisfactionComponent.scss";
import { FC, useContext } from "react";
import TableSatisfactionComponentProps from "./TableSatisfactionComponentProps";
import ButtonComponent from "../../../../components/button/ButtonComponent";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";
import LocalizationContext from "../../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";

const TableSatisfactionComponent: FC<TableSatisfactionComponentProps> = ({ colors, data, title }) => {
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="table_satisfaction_component">
        <h5>{title}</h5>
        <div className="w-100 overflow-x-auto">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTitleClient)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTitleOPType)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTotalCalls)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentSentiments)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentDetails)}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((client, index) => <tr key={index}>
                        <td>{client.name}</td>
                        <td>{client.opType}</td>
                        <td>{client.totalCalls}</td>
                        <td>
                            <div className="d-flex">
                                <div className="mr-2 sentiment_conent" style={{background: colors[0]}}>
                                    <span className="material-symbols-outlined">
                                        sentiment_dissatisfied
                                    </span>
                                    <span>{client.sentiments.negative}</span>
                                </div>
                                <div className="mr-2 sentiment_conent" style={{background: colors[1]}}>
                                    <span className="material-symbols-outlined">
                                        sentiment_neutral
                                    </span>
                                    <span>{client.sentiments.neutral}</span>
                                </div>
                                <div className="mr-2 sentiment_conent" style={{background: colors[2]}}>
                                    <span className="material-symbols-outlined">
                                        sentiment_satisfied
                                    </span>
                                    <span>{client.sentiments.positive}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ButtonComponent icon={
                                <span className="material-symbols-outlined">
                                    visibility
                                </span>
                            } />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}

export default TableSatisfactionComponent;