import { injectable } from "inversify";
import QARepository from "../../repositories/QARepository";
import QAAgentEntity from "../../entities/QAAgentEntity";
import { Either, isLeft } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";
import QAResultEntity from "../../entities/QAResultEntity";
import KeyWordLocalization from "../../../presentation/utils/KeyWordLocalization";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class DeleteAgentUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (id: string) : Promise<Either<ErrorEntity, void>> => {
        return this.qaRepository.deleteAgent(id);
    }
}

export const DeleteAgentUseCaseName = "DeleteAgentUseCase";