import { injectable } from "inversify";
import AuthRepository from "../../../domain/repositories/AuthRepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import LoginWithUserAndPasswordApiImpl from "./api/LoginWithUserAndPasswordApiImpl";
import GetCurrentUserApiImpl from "./api/GetCurrentUserApiImpl";

@injectable()
export default class AuthRepositoryImpl implements AuthRepository {
    getCurrentUser = (): Promise<UserEntity | null> => GetCurrentUserApiImpl();
    loginWithEmailAndPassword = (email: string, password: string): Promise<Either<ErrorEntity, UserEntity>> => LoginWithUserAndPasswordApiImpl(email, password);
    loginWithGoogle(): Promise<Either<ErrorEntity, UserEntity>> {
        throw new Error("Method not implemented.");
    }
    loginWithFacebook(): Promise<Either<ErrorEntity, UserEntity>> {
        throw new Error("Method not implemented.");
    }
    loginWithApple(): Promise<Either<ErrorEntity, UserEntity>> {
        throw new Error("Method not implemented.");
    }
    logout(): Promise<Either<ErrorEntity, void>> {
        throw new Error("Method not implemented.");
    }

}