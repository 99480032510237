import "./ButtonComponentStyles.scss";
import { FC } from "react";
import ButtonComponentProps from "./ButtonComponentProps";

const ButtonComponent: FC<ButtonComponentProps> = ({ onClick, text, className, design = "primary", disabled, height, icon, isLoading, style, type = "button", width }) => {
    return (
        <button className={`button_component ${design} btn ${className}`} style={{
            ...style,
            height,
            width,
        }}
            type={type}
            disabled={isLoading || disabled} onClick={onClick}> {
                icon || isLoading && <div className="icon">{!isLoading ? icon
                    : <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                 }</div>
            } {text}</button>
    );
}

export default ButtonComponent;