import { injectable } from "inversify";
import AuthRepository from "../../../domain/repositories/AuthRepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import UserEntity from "../../../domain/entities/UserEntity";
import AuthRepositoryFake from "./AuthRepositoryFake";
import LoginWithUserAndPasswordApiImpl from "./api/LoginWithUserAndPasswordApiImpl";
import GetCurrentUserApiImpl from "./api/GetCurrentUserApiImpl";

@injectable()
export default class AuthRepositoryDev implements AuthRepository {
    fakeRepository: AuthRepositoryFake = new AuthRepositoryFake();
    getCurrentUser = (): Promise<UserEntity | null> => GetCurrentUserApiImpl();
    loginWithEmailAndPassword = (email: string, password: string): Promise<Either<ErrorEntity, UserEntity>> => LoginWithUserAndPasswordApiImpl(email, password);
    loginWithGoogle = (): Promise<Either<ErrorEntity, UserEntity>> => this.fakeRepository.loginWithGoogle();
    loginWithFacebook = (): Promise<Either<ErrorEntity, UserEntity>> => this.fakeRepository.loginWithFacebook();
    loginWithApple = (): Promise<Either<ErrorEntity, UserEntity>> => this.fakeRepository.loginWithApple();
    logout = (): Promise<Either<ErrorEntity, void>> => this.fakeRepository.logout();
}