import { FC } from "react";
import BarSatifactionComponentProps from "./BarSatisfactionComponentProps";
import { Bar, Line } from "react-chartjs-2";

const BarSatifactionComponent: FC<BarSatifactionComponentProps> = ({ title, chartData, yTitle }) => {
    return <div className="bar_satisfaction_component">
        <h5 className="mb-3">{title}</h5>
        <Line data={chartData} options={{
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
            },
            responsive: true,
            scales: {
                y: yTitle !== undefined ? {
                    title: {
                        display: true,
                        text: yTitle
                    }
                }: {}
            },

        }} />
    </div>
}

export default BarSatifactionComponent;