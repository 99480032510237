import { injectable } from "inversify";
import QARepository from "../../repositories/QARepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";
import QAQuestionEntity from "../../entities/QAQuestionEntity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class UpdateQuestionForAgentUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        console.log('UpdateQuestionForAgentUseCase', question);
        return this.qaRepository.updateQuestionFromAgent(question);
    }
}

export const UpdateQuestionForAgentUseCaseName = "UpdateQuestionForAgentUseCase";