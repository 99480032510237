import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import { AverageCalls, SentimentsOnDate } from "../../../../domain/repositories/DashboardRepository";
import DateDto from "../../../dto/DateDto";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import SentimentsOnDateDto from "../../../dto/SentimentsOnDateDto";

const GetDayToDayApiImpl = async (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, SentimentsOnDate[]>> => {
    try {

        const relativeUrl = `/stats/call_sentiments/?start_date=${DateDto.toServer(startDate)}&end_date=${DateDto.toServer(endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        console.log("getDayToDayApiImpl response", response);
        const parsed = response.map((item: any) => SentimentsOnDateDto.fromJson(item));
        return right(parsed);
    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default GetDayToDayApiImpl;