import { injectable } from "inversify";
import QARepository from "../../repositories/QARepository";
import QAAgentEntity from "../../entities/QAAgentEntity";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class UpdateAgentUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (agent: QAAgentEntity) : Promise<Either<ErrorEntity, QAAgentEntity>> => {
        return this.qaRepository.updateAgent(agent);
    }
}

export const UpdateAgentUseCaseName = "UpdateAgentUseCase";