import { Either } from "fp-ts/lib/Either";
import MasterItemEntity from "../entities/MasterItemEntity";
import ErrorEntity from "../entities/ErrorEntity";

export interface AverageCalls {
    calls: number,
    agent: number,
    customer: number,
};


export interface Sentiments {
    negative: number,
    neutral: number,
    positive: number,
}

export interface SentimentsOnDate {
    date: Date,
    sentiments: Sentiments
}

export interface Target {
    serviceLevel: number,
    current: number,
    increment: number,
}

export interface ReasonForCalls {
    name: string,
    count: number,
}

export interface Client {
    name: string,
    opType: string,
    totalCalls: number,
    sentiments: Sentiments
}

export interface GetOperationReportResponse {
    dayToDay?: SentimentsOnDate[],
    totalSentiments?: Sentiments,
    averages?: AverageCalls,
    totalCalls?: number,
    target?: Target 
    reasonForCalls?: ReasonForCalls[],
    bestClients?: Client[],
    worstClients?: Client[],
}

export default interface DashboardRepository {
    getClientsList: () => Promise<Either<ErrorEntity, MasterItemEntity[]>>;
    getCallAverages: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, {averages: AverageCalls, totalCalls: number}>>;
    getSentiments: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, SentimentsOnDate[]>>;
    getReasonForCalls: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, ReasonForCalls[]>>;
    getScoreDetails: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, Target>>;
    getClientOps: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, {best: Client[], worst: Client[]}>>;
}

export const DashboardRepositoryName = "DashboardRepository";