import "./ReasonsForCallComponent.scss";
import { FC } from "react";
import ReasonsForCallComponentProps from "./ReasonsForCallComponentProps";

const ReasonsForCallComponent: FC<ReasonsForCallComponentProps> = ({ data, title }) => {
    //some random colors
    const sortData = data.sort((a, b) => b.percentage - a.percentage);
    const colors = ["#ADD8E6", "#98FB98", "#FDFD96", "#DDA0DD", "#FFB6C1", "#FFDAB9"];
    return <div className="reasons_for_call_component">
        <h4>{title}</h4>
        {sortData.map((reason, index) => <div className="item_reason">
            <div className="reason">{reason.name}</div>
            <div className="percentage_container">
                <div className="bar_percentage" style={{
                    width: reason.percentage + "%",
                    background: colors[index % colors.length]
                }}></div>
                <div className="percentage">{reason.percentage}%</div>
            </div>
        </div>)}
    </div>
}

export default ReasonsForCallComponent;