export default interface ErrorEntity {
    code?: string;
    message?: string;
}

export const ErrorEntityFromError = (error: any): ErrorEntity => {
    try {
        return {
            code: 'ErrorHost' + error.code,
        }
    } catch (error) {
        return {}
    }
}