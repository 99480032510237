import { ErrorMessage } from '@hookform/error-message';
import { FC, useContext, useState } from "react";
import KeyWordLocalization from '../../../utils/KeyWordLocalization';
import Validators from '../../../utils/Validators';
import { useForm } from 'react-hook-form';
import LocalizationContext from '../../../providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import ButtonComponent from '../../../components/button/ButtonComponent';
import di from '../../../../DependencyInjection';
import LoginWithEmailAndPasswordUseCase, { LoginWithEmailAndPasswordUseCaseName } from '../../../../domain/use_cases/auth/LoginWithEmailAndPasswordUseCase';
import { isLeft } from 'fp-ts/lib/Either';
import ModalsContext from '../../../providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes/Routes';
import AuthLayoutComponent from '../../../layouts/authLayout/AuthLayoutComponent';

const SignInPage: FC<{}> = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const _handleSubmit = async (data: any) => {
        if (loading) return;
        setLoading(true);
        const response = await di.get<LoginWithEmailAndPasswordUseCase>(LoginWithEmailAndPasswordUseCaseName).call(data[KeyWordLocalization.SignInPageEmail], data[KeyWordLocalization.SignInPagePassword]);
        setLoading(false);
        console.log("sas", response);
        if (isLeft(response)) {
            addToast(response.left.code ?? KeyWordLocalization.UnknownError, "error", undefined, undefined);
            return;
        }
        setLoading(false);
        navigate(routes.home.relativePath);
        addToast(i18n(KeyWordLocalization.SignInPageSuccess), "success", undefined, undefined);
    }

    return <AuthLayoutComponent>
        <form onSubmit={handleSubmit(_handleSubmit)}>
            <h1 className='text_color_primary_dark font-weight-bold'>{i18n(KeyWordLocalization.SignInPageTitle)}</h1>
            <p className='text_color_primary_dark'>{i18n(KeyWordLocalization.SignInPageSubtitle)}</p>
            <div className={`form-group my-3 ${errors[KeyWordLocalization.SignInPageEmail] && 'error error_group'}`}>
                <label className='form-label'>{i18n(KeyWordLocalization.SignInPageEmail)}</label>
                <input type="text" className="form-control" {...register(KeyWordLocalization.SignInPageEmail, Validators({
                    required: true,
                }))} />
                <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.SignInPageEmail} />
            </div>
            <div className={`form-group my-3 ${errors[KeyWordLocalization.SignInPagePassword] && 'error error_group'}`}>
                <label className='form-label'>{i18n(KeyWordLocalization.SignInPagePassword)}</label>
                <input type="password" className="form-control" {...register(KeyWordLocalization.SignInPagePassword, Validators({
                    required: true,
                }))} />
                <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.SignInPagePassword} />
            </div>
            <div className="w-100 d-flex justify-content-center">
                <ButtonComponent icon={<span className="material-symbols-outlined">
                    login
                </span>} isLoading={loading} text={i18n(KeyWordLocalization.SignInPageButtonSignIn)} type="submit" design="primary" />

            </div>
        </form>

    </AuthLayoutComponent>
}

export default SignInPage;