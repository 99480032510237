import UserEntity from "../../../../domain/entities/UserEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import UserDto from "../../../dto/UserDto";

const GetCurrentUserApiImpl = async (): Promise<UserEntity | null> => {
    try {
        const relativeRoute = "/validate-token";
        const response = await di.get<HostApi>(HostApiName).get(relativeRoute);
        const userParsed = UserDto.fromJSON(response);
        return userParsed;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export default GetCurrentUserApiImpl;