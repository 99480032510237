import { injectable } from "inversify";
import AuthRepository from "../../../domain/repositories/AuthRepository";
import { Either, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import UserEntity, { UserType } from "../../../domain/entities/UserEntity";
import Testing from "../../../presentation/utils/Testing";

export const fakeUser: UserEntity = {
    name: "Fake User",
    photo: "https://concepto.de/wp-content/uploads/2018/08/persona-e1533759204552.jpg",
    clientName: "Fake Client",
    operationName: "Fake Operation",
    role: UserType.ADMIN,
}

@injectable()
class AuthRepositoryFake implements AuthRepository {
    loggedKey:string = "logged";
    getCurrentUser = async (): Promise<UserEntity | null> => {
        const isLogged = localStorage.getItem(this.loggedKey);
        if(isLogged)
            return fakeUser;
        else
            return null;
    }
    loginWithEmailAndPassword = async (email: string, password: string): Promise<Either<ErrorEntity, UserEntity>> => {
        localStorage.setItem(this.loggedKey, "true");
        await Testing.sleeper(1000);
        return right(fakeUser);
    }
    loginWithGoogle = async (): Promise<Either<ErrorEntity, UserEntity>> => {
        await Testing.sleeper(1000);
        return right(fakeUser);
    }
    loginWithFacebook = async (): Promise<Either<ErrorEntity, UserEntity>> => {
        await Testing.sleeper(1000);
        return right(fakeUser);
    }
    loginWithApple = async (): Promise<Either<ErrorEntity, UserEntity>> => {
        await Testing.sleeper(1000);
        return right(fakeUser);
    }
    logout = async (): Promise<Either<ErrorEntity, void>> => {
        localStorage.removeItem(this.loggedKey);
        await Testing.sleeper(1000);
        return right(undefined);
    }
}

export default AuthRepositoryFake;