import "./DashboardPage.scss";
import { FC, useContext, useEffect, useState } from "react";
import LayoutComponent from "../../layouts/layoutComponent/LayoutComponent";
import di from "../../../DependencyInjection";
import GetOperationReportUseCase, { GetOperationReportUseCaseName } from "../../../domain/use_cases/dashboard/GetOperationReportUseCase";
import { isLeft } from "fp-ts/lib/Either";
import ModalsContext from "../../providers/modal/ModalsContext";
import ModalsContextType from "../../../domain/providers/modal/ModalsContextType";
import KeyWordLocalization from "../../utils/KeyWordLocalization";
import { GetOperationReportResponse } from "../../../domain/repositories/DashboardRepository";
import DatesUtils from "../../utils/DatesUtils";
import BarSatifactionComponent from "./components/barSatisfaction/BarSatisfactionComponent";
import TableSatisfactionComponent from "./components/tableSatisfaction/TableSatisfactionComponent";
import CardFeelingComponent from "./components/cardFeeling/CardFeelingComponent";
import ReasonsForCallComponent from "./components/reasonsForCall/ReasonsForCallComponent";
import TargetCircleComponent from "./components/targetCircle/TargetCircleComponent";
import LocalizationContext from "../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../utils/Validators";
import MasterItemEntity from "../../../domain/entities/MasterItemEntity";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../components/button/ButtonComponent";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/Routes";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

const DashboardPage: FC<{}> = () => {
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [data, setData] = useState<GetOperationReportResponse | null | undefined>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [opTypes, setOpTypes] = useState<MasterItemEntity[]>([]);
    const colors = ['#ff645e', '#8c8484', '#73bf94'];

    const queryParameters = new URLSearchParams(window.location.search);
    const opType = queryParameters.get(i18n(KeyWordLocalization.DashboardPageUrlParamOptype));
    const dateFrom = queryParameters.get(i18n(KeyWordLocalization.DashboardPageUrlParamDateFrom));
    const dateTo = queryParameters.get(i18n(KeyWordLocalization.DashboardPageUrlParamDateTo));

    const _getDashboardData = async () => {
        setData(undefined);
        setLoaded(false);
        const initDate = dateFrom ? new Date(dateFrom) : new Date();
        const endDate = dateTo ? new Date(dateTo) : new Date();
        if (dateTo == null) initDate.setDate(initDate.getDate() - 8);
        const _callBack = (_da: GetOperationReportResponse) => {
            console.log('update data', _da);
            setData(_da);
        }
        const response = await di.get<GetOperationReportUseCase>(GetOperationReportUseCaseName).call("", initDate, endDate, _callBack);
        if (isLeft(response)) {
            setData(null);
            setLoaded(true);
            return addToast(response.left.code ?? KeyWordLocalization.UnknownError, "error", undefined);
        } else {
            setLoaded(true);
            setData(response.right);
        }
    }

    const parsedChartData = (): {
        labels: string[],
        datasets: {
            label: string,
            data: number[],
            backgroundColor: string
        }[]
    } => {
        const labels = data!.dayToDay!.map(day => DatesUtils.dayForGraphs(day.date));
        const datasets = [{
            label: i18n(KeyWordLocalization.DashboardPageSentimentNegative),
            data: data!.dayToDay!.map(day => day.sentiments.negative),
            backgroundColor: colors[0] + "33",
            borderColor: colors[0],
            fill: true,
        },
        {
            label: i18n(KeyWordLocalization.DashboardPageSentimentNeutral),
            data: data!.dayToDay!.map(day => day.sentiments.neutral),
            backgroundColor: colors[1] + "33",
            borderColor: colors[1],
            fill: true,
        },
        {
            label: i18n(KeyWordLocalization.DashboardPageSentimentPositive),
            data: data!.dayToDay!.map(day => day.sentiments.positive),
            backgroundColor: colors[2] + "33",
            borderColor: colors[2],
            fill: true,
        }];
        return {
            labels,
            datasets
        }
    }

    const _reduceFeelings = (feelingsList: number[]): number => {
        if (feelingsList.length === 0) return 0;
        const reduced = feelingsList.reduce((a, b) => a + b, 0);
        return Math.round(reduced);
    }

    const _getPercentFeelings = (feelingsList: number[]): number => {
        if (data!.totalCalls == undefined || data?.totalCalls == 0) return 0;
        const reduced = _reduceFeelings(feelingsList);
        if (reduced === 0) return 0;
        const percentage = (reduced / data!.totalCalls) * 100;
        return Math.round(percentage);
    }

    const _handleLoadDefaults = () => {
        if (dateFrom) setValue(KeyWordLocalization.DashboardPageDateFromForm, dateFrom);
        if (dateTo) setValue(KeyWordLocalization.DashboardPageDateToForm, dateTo);
        if (opType) setValue(KeyWordLocalization.DashboardPageOpTypeForm, opType);
    }

    const _onSubmit = (data: any) => {
        console.log("data", data);
        //add url params ?opType=1&dateFrom=2021-09-01&dateTo=2021-09-30
        const params = new URLSearchParams();
        if (data[KeyWordLocalization.DashboardPageOpTypeForm]) params.append(i18n(KeyWordLocalization.DashboardPageUrlParamOptype), data[KeyWordLocalization.DashboardPageOpTypeForm]);
        if (data[KeyWordLocalization.DashboardPageDateFromForm]) params.append(i18n(KeyWordLocalization.DashboardPageUrlParamDateFrom), data[KeyWordLocalization.DashboardPageDateFromForm]);
        if (data[KeyWordLocalization.DashboardPageDateToForm]) params.append(i18n(KeyWordLocalization.DashboardPageUrlParamDateTo), data[KeyWordLocalization.DashboardPageDateToForm]);
        const newUrl = routes.home.relativePath + "?" + params.toString();
        console.log(newUrl);
        navigate(newUrl);
    }

    useEffect(() => {
        _handleLoadDefaults();
        _getDashboardData();
    }, [dateFrom, dateTo, opType]);

    return <div className="dashboard_page">
        <LayoutComponent title="Dashboard">
            <div className="container">
                <form onSubmit={handleSubmit(_onSubmit)}>
                    <div className="row">
                        <div className={`form-group col-md-6 my-3 ${errors[KeyWordLocalization.DashboardPageOpTypeForm] && 'error error_group'}`}>
                            <label className='form-label'>{i18n(KeyWordLocalization.DashboardPageOpTypeForm)}</label>
                            <select {...register(KeyWordLocalization.DashboardPageOpTypeForm, Validators({
                            }))} className="form-control">
                                <option value="">All types</option>
                                {opTypes.map((opType) => <option key={opType.id} value={opType.id}>{opType.name}</option>)}
                            </select>
                            <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.DashboardPageOpTypeForm} />
                        </div>
                        <div className={`form-group col-md-2 my-3 ${errors[KeyWordLocalization.DashboardPageDateFromForm] ? 'error' : ''}`}>
                            <label className="form-label">{i18n(KeyWordLocalization.DashboardPageDateFromForm)}</label>
                            <input type="date" className="form-control"
                                max={new Date().toISOString().split('T')[0]}
                                {...register(KeyWordLocalization.DashboardPageDateFromForm, Validators({ required: true }))} />
                            <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.DashboardPageDateFromForm} />
                        </div>
                        <div className={`form-group col-md-2 my-3 ${errors[KeyWordLocalization.DashboardPageDateToForm] ? 'error' : ''}`}>
                            <label className="form-label">{i18n(KeyWordLocalization.DashboardPageDateToForm)}</label>
                            <input type="date" className="form-control"
                                max={new Date().toISOString().split('T')[0]}
                                {...register(KeyWordLocalization.DashboardPageDateToForm, Validators({ required: true, greaterOrEqualThan: watch(KeyWordLocalization.DashboardPageDateFromForm) }))} />
                            <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.DashboardPageDateToForm} />
                        </div>
                        <div className="col-md-2 my-3 d-flex align-items-end">
                            <ButtonComponent className="w-100 justify-content-center" type="submit" icon={
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            } text={i18n(KeyWordLocalization.Search)} />
                        </div>
                    </div>
                </form>
                {
                    data && <div className="row">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-md-3 my-3" style={{ fontSize: '1.3em' }}>
                                    {data.totalCalls && <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageTotalCalls)} className="h-100" total={data.totalCalls} icon={
                                        <span className="material-symbols-outlined">
                                            call
                                        </span>
                                    } />}
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        {!loaded || data.averages &&
                                            <div className="col-lg-4 col-md-6 my-3">
                                                {data.averages &&
                                                    <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageAverageCallScore)} total={data.averages.calls + "%"} />
                                                }
                                            </div>}
                                        {!loaded || data.averages && <div className="col-lg-4 col-md-6 my-3">
                                            {data.averages &&
                                                <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageAverageAgentScore)} total={data.averages.calls + "%"} />
                                            }</div>}
                                        {!loaded || data.averages && <div className="col-lg-4 col-md-6 my-3">
                                            {data.averages &&
                                                <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageAverageCustomerScore)} total={data.averages.calls + "%"} />
                                            }</div>}
                                        {!loaded || data.totalSentiments &&
                                            <div className="col-lg-4 col-md-6 my-3">
                                                {data.totalSentiments &&
                                                    <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageSentimentNegative)} total={data.totalSentiments.negative} color={colors[0]} icon={
                                                        <span className="material-symbols-outlined">
                                                            sentiment_dissatisfied
                                                        </span>
                                                    } percentage={_getPercentFeelings([data.totalSentiments.negative])} />
                                                }</div>}
                                        {!loaded || data.totalSentiments &&
                                            <div className="col-lg-4 col-md-6 my-3">
                                                {data.totalSentiments &&
                                                    <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageSentimentNeutral)} total={data.totalSentiments.neutral} color={colors[1]} icon={
                                                        <span className="material-symbols-outlined">
                                                            sentiment_neutral
                                                        </span>
                                                    } percentage={_getPercentFeelings([data.totalSentiments.neutral])} />
                                                }</div>}
                                        {!loaded || data.totalSentiments &&
                                            <div className="col-lg-4 col-md-6 my-3">
                                                {data.totalSentiments &&
                                                    <CardFeelingComponent title={i18n(KeyWordLocalization.DashboardPageSentimentPositive)} total={data.totalSentiments.positive} color={colors[2]} icon={
                                                        <span className="material-symbols-outlined">
                                                            sentiment_satisfied
                                                        </span>
                                                    } percentage={_getPercentFeelings([data.totalSentiments.positive])} />
                                                }</div>}
                                    </div>
                                </div>
                                {!loaded || data.dayToDay &&
                                    <div className="card b-none p-4 my-3">
                                        {data.dayToDay &&
                                            <BarSatifactionComponent title={i18n(KeyWordLocalization.DashboardPageSentimentsOnTime)} chartData={parsedChartData()} yTitle={i18n(KeyWordLocalization.DashboardPageTotalCalls)} />
                                        }</div>}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {!loaded || data.target &&
                                <div className="card my-3">
                                    <div className="p-3">
                                        {data.target &&
                                            <TargetCircleComponent target={data.target.serviceLevel} current={data.target.current} increment={data.target.increment} />
                                        }</div>
                                </div>}
                            {!loaded || data.reasonForCalls &&
                                <div className="card">
                                    <div className="card-body">
                                        {data.reasonForCalls &&
                                            <ReasonsForCallComponent title={i18n(KeyWordLocalization.DashboardPageReasonsForCall)} data={data.reasonForCalls.map((reason) => {
                                                return {
                                                    name: reason.name,
                                                    percentage: reason.count,
                                                }
                                            })} />
                                        }
                                    </div>
                                </div>}
                        </div>
                        <div className="row">
                            {!loaded || data.bestClients && <div className="col-md-6">
                                <div className="card b-none p-4 my-3">
                                    {data.bestClients != undefined ? <TableSatisfactionComponent title={i18n(KeyWordLocalization.DashboardPageBestClients)} data={data.bestClients} colors={colors} /> : <LoadingComponent />}
                                </div>
                            </div>}
                            {!loaded || data.worstClients && <div className="col-md-6">
                                <div className="card b-none p-4 my-3">
                                    {data.worstClients != undefined ? <TableSatisfactionComponent title={i18n(KeyWordLocalization.DashboardPageWorstClients)} data={data.worstClients} colors={colors} /> : <LoadingComponent />}
                                </div>
                            </div>
                            }
                        </div>

                    </div>
                }

            </div>
        </LayoutComponent>
    </div>
}

export default DashboardPage;