import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../entities/ErrorEntity";
import QAAgentEntity from "../entities/QAAgentEntity";
import QAResultEntity from "../entities/QAResultEntity";
import QAQuestionEntity from "../entities/QAQuestionEntity";
import SearchResultEntity from "../entities/SearchResultEntity";

export default interface QARepository {
    uploadAudioForTranscription(audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>>;
    getAudioResults(jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>>;
    createAgent(agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>>;
    searchAgents(query: string | undefined, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>>;
    getAgentById(id: string): Promise<Either<ErrorEntity, QAAgentEntity>>;
    addQuestionToAgent(agentId: string, question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>>;
    deleteQuestionFromAgent(questionId: string): Promise<Either<ErrorEntity, void>>;
    updateQuestionFromAgent(question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>>;
    deleteAgent(id: string): Promise<Either<ErrorEntity, void>>
    updateAgent(agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>>;
}

export const QARepositoryName = "QARepository";