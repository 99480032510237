import { FC, useContext, useEffect, useState } from "react";
import LayoutComponent from "../../../layouts/layoutComponent/LayoutComponent";
import { useNavigate, useParams } from "react-router-dom";
import QAAgentEntity from "../../../../domain/entities/QAAgentEntity";
import di from "../../../../DependencyInjection";
import GetAgentByIdUseCase, { GetAgentByIdUseCaseName } from "../../../../domain/use_cases/qa/GetAgentByIdUseCase";
import { isLeft } from "fp-ts/lib/Either";
import { useForm } from "react-hook-form";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../utils/Validators";
import ButtonComponent from "../../../components/button/ButtonComponent";
import QAQuestionEntity from "../../../../domain/entities/QAQuestionEntity";
import ModalsContext from "../../../providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CreateQuestionComponent from "./widgets/createForm/CreateQuestionComponent";
import routes from "../../../routes/Routes";
import CreateAgentUseCase, { CreateAgentUseCaseName } from "../../../../domain/use_cases/qa/CreateAgentUseCase";
import UpdateAgentUseCase, { UpdateAgentUseCaseName } from "../../../../domain/use_cases/qa/UpdateAgentUseCase";
import CreateQuestionForAgentUseCase, { CreateQuestionForAgentUseCaseName } from "../../../../domain/use_cases/qa/CreateQuestionForAgentUseCase";
import UpdateQuestionForAgentUseCase, { UpdateQuestionForAgentUseCaseName } from "../../../../domain/use_cases/qa/UpdateQuestionForAgentUseCase";
import DeleteQuestionFromAgentUseCase, { DeleteQuestionFromAgentUseCaseName } from "../../../../domain/use_cases/qa/DeleteQuestionFromAgentUseCase";

const CreateAgentPage: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast, openModalCustom, openModalDelete } = useContext(ModalsContext) as ModalsContextType;
    const [questions, setQuestions] = useState<QAQuestionEntity[]>([]);
    const [agent, setAgent] = useState<QAAgentEntity | null | undefined>(undefined);
    const navigate = useNavigate();

    const _handleCreateAgent = async (data: any) => {
        const fakeAgent: QAAgentEntity = {
            id: '',
            name: data[KeyWordLocalization.CreateAgentPageTitle],
            questions: questions,
            client: '',
            opType: '',
        };
        if (questions.length == 0) return addToast(i18n(KeyWordLocalization.CreateAgentPageNoQuestions), 'error', undefined);
        const response = await di.get<CreateAgentUseCase>(CreateAgentUseCaseName).call(fakeAgent);
        if (isLeft(response)) return addToast(i18n(KeyWordLocalization.CreateAgentPageCreateError), 'error', undefined);
        addToast(i18n(KeyWordLocalization.CreateAgentPageCreateSuccess), 'success', undefined);
        navigate(routes.agent.relativePathWithParams({ id: response.right.id }), {
            replace: true,
        });
    }

    const _handleAddQuestion = () => {
        //add question 
        const _addQuestion = async (question: QAQuestionEntity) => {
            if (agent != null) {
                const response = await di.get<CreateQuestionForAgentUseCase>(CreateQuestionForAgentUseCaseName).call(agent.id, question);
                if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CreateAgentPageQuestionError), 'error', undefined);
                setQuestions([...questions, response.right]);
                addToast(i18n(KeyWordLocalization.CreateAgentPageQuestionAdded), 'success', undefined);
            } else {
                setQuestions([...questions, question]);
                addToast(i18n(KeyWordLocalization.CreateAgentPageQuestionAdded), 'success', undefined);
            }
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CreateAgentPageCreateQuestion), <CreateQuestionComponent onSave={_addQuestion} question={undefined} agentId={agent?.id} />);
    }

    const _handleEditQuestion = (questionPrev: QAQuestionEntity) => {
        //edit question
        const _editQuestion = async (question: QAQuestionEntity) => {
            if (agent != null) {
                const response = await di.get<UpdateQuestionForAgentUseCase>(UpdateQuestionForAgentUseCaseName).call(question);
                if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CreateAgentPageQuestionError), 'error', undefined);
                const index = questions.findIndex(q => q.title == questionPrev.title && q.points == questionPrev.points);
                if (index == -1) return;
                questions[index] = response.right;
                setQuestions([...questions]);
                addToast(i18n(KeyWordLocalization.CreateAgentPageQuestionUpdated), 'success', undefined);
            } else {
                const index = questions.findIndex(q => q.title == questionPrev.title && q.points == questionPrev.points);
                if (index == -1) return;
                questions[index] = question;
                setQuestions([...questions]);
                addToast(i18n(KeyWordLocalization.CreateAgentPageQuestionUpdated), 'success', undefined);
            }
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CreateAgentPageEditQuestion), <CreateQuestionComponent onSave={_editQuestion} question={questionPrev} agentId={agent?.id} />);
    }

    const _handleDeleteQuestion = (question: QAQuestionEntity) => {
        //delete question
        const _deleteQuestion = async () => {
            if (agent != null && question.id != '') {
                const response = await di.get<DeleteQuestionFromAgentUseCase>(DeleteQuestionFromAgentUseCaseName).call(question.id);
                if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CreateAgentPageQuestionError), 'error', undefined);
                const index = questions.findIndex(q => q.title == question.title && q.points == question.points);
                if (index == -1) return;
                questions.splice(index, 1);
                setQuestions([...questions]);
                addToast(i18n(KeyWordLocalization.CreateAgentPageQuestionDeleted), 'success', undefined);
            } else {
                const index = questions.findIndex(q => q.title == question.title && q.points == question.points);
                if (index == -1) return;
                questions.splice(index, 1);
                setQuestions([...questions]);
                addToast(i18n(KeyWordLocalization.CreateAgentPageQuestionDeleted), 'success', undefined);
            }
        }
        openModalDelete(i18n(KeyWordLocalization.CreateAgentPageDeleteQuestionTitle), i18n(KeyWordLocalization.CreateAgentPageDeleteQuestionSubtitle), _deleteQuestion);
    }


    const _searchAgent = async () => {
        if (id == undefined) return setAgent(null);
        const response = await di.get<GetAgentByIdUseCase>(GetAgentByIdUseCaseName).call(id);
        if (isLeft(response)) return setAgent(null);
        setAgent(response.right);
        setValue(KeyWordLocalization.CreateAgentPageTitle, response.right.name);
        setQuestions(response.right.questions);
    }

    useEffect(() => {
        _searchAgent();
    }, [id]);

    if (agent == null && id) return <div className="update_agent_page">
        <LayoutComponent title={i18n(KeyWordLocalization.CreateAgentPageEditTitle)}>
            <div className="container">
                {agent === null ?
                    <NotFoundComponent />
                    : <LoadingComponent />}
            </div>
        </LayoutComponent>
    </div>

    return <div className="create_agent_page">
        <LayoutComponent title={i18n(id != null ? KeyWordLocalization.CreateAgentPageEditTitle : KeyWordLocalization.CreateAgentPageAddTitle)}>
            <div className="container">
                <form onSubmit={handleSubmit(_handleCreateAgent)}>
                    <div className={`form-group my-3 ${errors[KeyWordLocalization.CreateAgentPageTitle] && 'error error_group'}`}>
                        <label className='form-label'>{i18n(KeyWordLocalization.CreateAgentPageTitle)}</label>
                        <input type="text" disabled={agent != null} className="form-control" placeholder={i18n(KeyWordLocalization.CreateAgentPageTitle)} {...register(KeyWordLocalization.CreateAgentPageTitle, Validators({
                            required: true,
                            minLength: 5,
                        }))} />
                        <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateAgentPageTitle} />
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <h5>{i18n(KeyWordLocalization.CreateAgentPageQuestionsTitle)}</h5>
                                <ButtonComponent onClick={_handleAddQuestion} icon={
                                    <span className="material-symbols-outlined">add</span>
                                } type="button" className="btn btn-primary" text={i18n(KeyWordLocalization.CreateAgentPageAddQuestion)} />
                            </div>
                            <div className="py-3">
                                {questions.length == 0 && <div className="d-flex flex-column align-items-center">
                                    <div className="my-2">
                                        <strong>{i18n(KeyWordLocalization.CreateAgentPageNoQuestions)}</strong>
                                    </div>
                                    <ButtonComponent onClick={_handleAddQuestion} icon={
                                        <span className="material-symbols-outlined">add</span>
                                    } type="button" className="btn btn-primary" text={i18n(KeyWordLocalization.CreateAgentPageAddQuestion)} />
                                </div>}
                                {questions.map((question, index) => <div key={index} className="question_card my-3">
                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                        <h5>{question.title} ({question.points} {i18n(KeyWordLocalization.CreateAgentPageQuestionPoints)})</h5>
                                        <div className="d-flex align-items-center">
                                            <ButtonComponent icon={
                                                <span className="material-symbols-outlined">edit</span>
                                            } type="button" design="primaryOutline" className="mx-2" text={i18n(KeyWordLocalization.CreateAgentPageEdit)} onClick={() => _handleEditQuestion(question)} />
                                            <ButtonComponent icon={
                                                <span className="material-symbols-outlined">delete</span>
                                            } type="button" design="dangerOutline" text={i18n(KeyWordLocalization.CreateAgentPageDelete)}
                                                onClick={() => _handleDeleteQuestion(question)} />
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <p>{question.description}</p>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-end my-3">
                        {agent == null &&
                            <ButtonComponent icon={<span className="material-symbols-outlined">
                                add </span>} type="submit" design="primary" text={i18n(KeyWordLocalization.CreateAgentCreate)} />}
                    </div>
                </form>
            </div>
        </LayoutComponent>
    </div >
};

export default CreateAgentPage;