import "./AuthInfoComponentStyles.scss";
import { FC, useContext } from "react";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../providers/localization/LocalizationContext";
import KeyWordLocalization from "../../utils/KeyWordLocalization";

const AuthInfoComponent: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="auth_info_component">
        <img src="./assets/logo/long_gray.png" className="logo" alt="" />

        <h3>{i18n(KeyWordLocalization.AuthIfonComponentRevolutionTitle)}</h3>
        <p>{i18n(KeyWordLocalization.AuthIfonComponentRevolutionSubitle)}</p>
        <br />
        <h3>{i18n(KeyWordLocalization.AuthIfonComponentAIPoweredTitle)}</h3>
        <p>{i18n(KeyWordLocalization.AuthIfonComponentAIPoweredSubitle)}</p>
        <br />
        <h3>{i18n(KeyWordLocalization.AuthIfonComponentFastTitle)}</h3>
        <p>{i18n(KeyWordLocalization.AuthIfonComponentFastSubtitle)}</p>
        <br />
        <h3>{i18n(KeyWordLocalization.AuthIfonComponentBoostTitle)}</h3>
        <p>{i18n(KeyWordLocalization.AuthIfonComponentBoostSubtitle)}</p>
    </div>
}

export default AuthInfoComponent;