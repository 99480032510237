import { injectable } from "inversify";
import DashboardRepository from "../../repositories/DashboardRepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";
import MasterItemEntity from "../../entities/MasterItemEntity";

interface props {
    dashboardRepository: DashboardRepository;
}

@injectable()
class GetClientsListUseCase {
    private dashboardRepository: DashboardRepository;

    constructor({ dashboardRepository }: props) {
        this.dashboardRepository = dashboardRepository;
    }

    public call = async (): Promise<Either<ErrorEntity, MasterItemEntity[]>> => {
        return this.dashboardRepository.getClientsList();
    }
}

export default GetClientsListUseCase;

export const GetClientsListUseCaseName = "GetClientsListUseCase";