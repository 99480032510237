import path from "path";
import UserEntity from "../../domain/entities/UserEntity";
import AgentListPage from "../pages/agents/list/AgentListPage";
import SignInPage from "../pages/auth/signin/SignInPage";
import CreateAgentPage from "../pages/agents/create/CreateAgentPage";
import DetailedAgentPage from "../pages/agents/detailed/DetailedAgentPage";
import DashboardPage from "../pages/home/DashboardPage";

export interface RouteEntity {
    path: string;
    relativePath: string;
    component: any;
    auth: (user: UserEntity | undefined | null) => boolean;
    title?: string;
    relativePathWithParams?: (params: any) => string;
};
const routes = {
    login: {
        path: "/",
        relativePath: "/",
        component: SignInPage,
        auth: (user: UserEntity | undefined | null) => !user,
        title: "Login",
        relativePathWithParams: (params: any) => "/",
    },
    home: {
        path: "/dashboard",
        relativePath: "/dashboard",
        component: DashboardPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Dashboard",
        relativePathWithParams: (params: any) => "/dashboard",
    },
    agent_list: {
        path: "/agents",
        relativePath: "/agents",
        component: AgentListPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Agents",
        relativePathWithParams: (params: any) => "/agents",
    },
    agent_create: {
        path: "/agents/create",
        relativePath: "/agents/create",
        component: CreateAgentPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Create Agent",
        relativePathWithParams: (params: any) => "/agents",
    },
    edit_agent: {
        path: "/agents/edit/:id",
        relativePath: "/agents/edit",
        component: CreateAgentPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Edit Agent",
        relativePathWithParams: (params: {id: string}) => `/agents/edit/${params.id}`,
    },
    agent: {
        path: "/agents/:id",
        relativePath: "/agents",
        component: DetailedAgentPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Agent",
        relativePathWithParams: (params: {id: string}) => `/agents/${params.id}`,
    }
};

export default routes;