import { injectable } from "inversify";
import DashboardRepository, { AverageCalls, Client, GetOperationReportResponse, ReasonForCalls, SentimentsOnDate, Target } from "../../../domain/repositories/DashboardRepository";
import { Either, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import MasterItemEntity from "../../../domain/entities/MasterItemEntity";
import Testing from "../../../presentation/utils/Testing";

@injectable()
export default class DashboardRepositoryFake implements DashboardRepository {

    getCallAverages = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, { averages: AverageCalls; totalCalls: number; }>> => {
        await Testing.sleeper(1400);
        const dates = Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24)));
        const dayToDay = dates.map(date => {
            return {
                date,
                sentiments: {
                    veryNegative: Math.floor(Math.random() * 10),
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                    veryPositive: Math.floor(Math.random() * 10),
                },
            }
        });
        const totals = {
            negative: dayToDay.reduce((acc, day) => acc + day.sentiments.negative, 0),
            neutral: dayToDay.reduce((acc, day) => acc + day.sentiments.neutral, 0),
            positive: dayToDay.reduce((acc, day) => acc + day.sentiments.positive, 0),
        }

        const total = totals.negative + totals.neutral + totals.positive;

        const averages = {
            calls: Math.floor(Math.random() * 100),
            agent: Math.floor(Math.random() * 100),
            customer: Math.floor(Math.random() * 100),
        };

        return right({
            averages,
            totalCalls: total,
        })
    }
    getSentiments = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, SentimentsOnDate[]>> => {
        await Testing.sleeper(800);
        const dates = Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24)));
        const dayToDay = dates.map(date => {
            return {
                date,
                sentiments: {
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                },
            }
        });
        return right(dayToDay);
    };
    getReasonForCalls = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, ReasonForCalls[]>> => {
        await Testing.sleeper(1100);
        //create an array of dates between the start and end date
        const reasonForCalls = Array.from({ length: 6 }, (_, i) => {
            return {
                name: `name ${i}`,
                count: parseFloat((Math.random() * 100).toFixed(2)),
            }
        });
        return right(reasonForCalls);
    }
    getScoreDetails = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, Target>> => {
        await Testing.sleeper(2000);
        const target = {
            serviceLevel: Math.floor(Math.random() * 100),
            current: Math.floor(Math.random() * 100),
            increment: parseFloat((Math.random() * 100).toFixed(2)),
        };
        return right(target);
    }
    getClientOps = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, { best: Client[]; worst: Client[]; }>> => {
        await Testing.sleeper(1600);
        const client = Array.from({ length: Math.floor(Math.random() * 10) }, (_, i) => {
            return {
                name: `name ${i}`,
                opType: `opType ${i}`,
                totalCalls: Math.floor(Math.random() * 100),
                sentiments: {
                    veryNegative: Math.floor(Math.random() * 10),
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                    veryPositive: Math.floor(Math.random() * 10),
                }
            }
        });
        return right({
            best: client,
            worst: client,
        });
    }

    getClientsList = async (): Promise<Either<ErrorEntity, MasterItemEntity[]>> => {
        const clients = Array.from({ length: Math.floor(Math.random() * 10) }, (_, i) => {
            const client: MasterItemEntity = {
                id: `id ${i}`,
                name: `name ${i}`,
            }
            return client;
        });
        return right(clients);
    }

}