import { injectable } from "inversify";
import DashboardRepository, { GetOperationReportResponse } from "../../repositories/DashboardRepository";
import { Either, isRight, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";

interface props {
    dashboardRepository: DashboardRepository;
}

@injectable()
class GetOperationReportUseCase {
    private dashboardRepository: DashboardRepository;

    constructor({ dashboardRepository }: props) {
        this.dashboardRepository = dashboardRepository;
    }

    public call = async (clientId: string | undefined, startDate: Date, endDate: Date, onGetOne: (data: GetOperationReportResponse) => void): Promise<Either<ErrorEntity, GetOperationReportResponse>> => {
        console.log("sended data", { clientId, startDate, endDate });
        //call to get tables first
        let data: GetOperationReportResponse = {};
        const _callCallDetails = async () => {
            const response = await this.dashboardRepository.getCallAverages(clientId, startDate, endDate);
            if (isRight(response)) {
                data.averages = response.right.averages;
                data.totalCalls = response.right.totalCalls;
                onGetOne(data);
            }
        }
        const _callTables = async () => {
            const response = await this.dashboardRepository.getClientOps(clientId, startDate, endDate);
            if (isRight(response)) {
                data.bestClients = response.right.best;
                data.worstClients = response.right.worst;
                onGetOne(data);
            }
        }

        const _callReasons = async () => {
            const response = await this.dashboardRepository.getReasonForCalls(clientId, startDate, endDate);
            if (isRight(response)) {
                data.reasonForCalls = response.right;
                onGetOne(data);
            }
        }

        const _callTarget = async () => {
            const response = await this.dashboardRepository.getScoreDetails(clientId, startDate, endDate);
            if (isRight(response)) {
                data.target = response.right;
                onGetOne(data);
            }
        }

        const _callSentiments = async () => {
            const response = await this.dashboardRepository.getSentiments(clientId, startDate, endDate);
            if (isRight(response)) {
                data.dayToDay = response.right;
                onGetOne(data);
            }
        }

        await Promise.all([
            _callCallDetails(),
            _callTables(),
            _callReasons(),
            _callTarget(),
            _callSentiments()
        ]);
        return right(data);
    }
}

export default GetOperationReportUseCase;

export const GetOperationReportUseCaseName = "GetOperationReportUseCase";