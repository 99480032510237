import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QAAgentEntity from "../../../../domain/entities/QAAgentEntity";
import { isLeft } from "fp-ts/lib/Either";
import di from "../../../../DependencyInjection";
import GetAgentByIdUseCase, { GetAgentByIdUseCaseName } from "../../../../domain/use_cases/qa/GetAgentByIdUseCase";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import LayoutComponent from "../../../layouts/layoutComponent/LayoutComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import ButtonComponent from "../../../components/button/ButtonComponent";
import routes from "../../../routes/Routes";
import AudioZoneComponent from "./components/audioZone/AudioZoneComponent";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import ResultIAAudioComponent from "./components/resultQuestion/ResultIAAudioComponent";
import QAResultEntity from "../../../../domain/entities/QAResultEntity";

const DetailedAgentPage: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const [agent, setAgent] = useState<QAAgentEntity | null | undefined>(undefined);
    const navigate = useNavigate();

    const _searchAgent = async () => {
        if (id == undefined) return setAgent(null);
        const response = await di.get<GetAgentByIdUseCase>(GetAgentByIdUseCaseName).call(id);
        if (isLeft(response)) return setAgent(null);
        setAgent(response.right);
    }

    const _handleEdit = () => navigate(routes.edit_agent.relativePathWithParams({ id: id! }));

    useEffect(() => {
        _searchAgent();
    }, [id]);

    return <div className="detailed_agent_page">
        <LayoutComponent title={i18n(KeyWordLocalization.DetailedAgentPageTitle)}>
            {agent === undefined && <LoadingComponent />}
            {agent === null && <NotFoundComponent />}
            {agent && <>
                <div className="container py-5">
                    <div className="w-100 d-flex justify-content-between">
                        <h1>{i18n(KeyWordLocalization.DetailedAgentPageTitle)}: {agent.name}</h1>
                        {/* <ButtonComponent onClick={_handleEdit} icon={
                            <span className="material-symbols-outlined">edit</span>
                        } text={KeyWordLocalization.edit} /> */}
                    </div>
                    <div className="row">
                        <div className="col-md-8 my-3">
                            <div className="card">
                                <div className="card-body">
                                    <h3>
                                        {i18n(KeyWordLocalization.DetailedAgentPageQuestions)}
                                    </h3>
                                    <div className="questions_container mt-3">
                                        {agent.questions.map((question, index) => <div key={index} className="question">
                                            <h5 className="mb-1">{question.title} ({question.points} {i18n(KeyWordLocalization.DetailedAgentPagePoints)})</h5>
                                            <p>{question.description}</p>
                                        </div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 my-3">
                            <div className="card">
                                <div className="card-body">
                                    <AudioZoneComponent qaAgent={agent} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </LayoutComponent>
    </div>
}

export default DetailedAgentPage;