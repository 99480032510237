import { Container } from "inversify";
import "reflect-metadata";
import Constants, { ConstantsName } from "./data/settings/constants/Constants";
import ConstantsFake from "./data/settings/constants/ConstantsFake";
import HostApi, { HostApiName } from "./data/settings/HostApi";
import ConstantsDev from "./data/settings/constants/ConstantsDev";
import ConstantsImpl from "./data/settings/constants/ConstantsImpl";
import LocalizationRepository, { LocalizationRepositoryName } from "./domain/repositories/LocalizationRepository";
import LocalizationRepositoryFake from "./data/repositories/localization/LocalizationRepositoryFake";
import LocalizationRepositoryDev from "./data/repositories/localization/LocalizationRepositoryDev";
import LocalizationProvider, { LocalizationProviderName } from "./domain/providers/localization/LocalizationProvider";
import LocalizationProviderImpl from "./presentation/providers/localization/LocalizationProviderImpl";
import UserProvider, { UserProviderName } from "./domain/providers/user/UserProvider";
import UserProviderImpl from "./presentation/providers/user/UserProviderImpl";
import ModalsProvider, { ModalsProviderName } from "./domain/providers/modal/ModalsProvider";
import ModalsProviderImpl from "./presentation/providers/modal/ModalsProviderImpl";
import LoadUseCase, { LoadUseCaseName } from "./domain/use_cases/default/LoadUseCase";
import GetAvailableLanguagesUseCase, { GetAvailableLanguagesUseCaseName } from "./domain/use_cases/localization/GetAvailableLanguagesUseCase";
import GetCurrentLanguageUseCase, { GetCurrentLanguageUseCaseName } from "./domain/use_cases/localization/GetCurrentLanguageUseCase";
import SetCurrentLanguageUseCase, { SetCurrentLanguageUseCaseName } from "./domain/use_cases/localization/SetCurrentLanguageUseCase";
import LoginWithEmailAndPasswordUseCase, { LoginWithEmailAndPasswordUseCaseName } from "./domain/use_cases/auth/LoginWithEmailAndPasswordUseCase";
import AuthRepository, { AuthRepositoryName } from "./domain/repositories/AuthRepository";
import LogoutUseCase, { LogoutUseCaseName } from "./domain/use_cases/auth/LogoutUseCase";
import AuthRepositoryFake from "./data/repositories/auth/AuthRepositoryFake";
import AuthRepositoryDev from "./data/repositories/auth/AuthRepositoryDev";
import AuthRepositoryImpl from "./data/repositories/auth/AuthRepositoryImpl";
import GetCurrentUserUseCase, { GetCurrentUserUseCaseName } from "./domain/use_cases/auth/GetCurrentUserUseCase";
import QARepository, { QARepositoryName } from "./domain/repositories/QARepository";
import QARepositoryFake from "./data/repositories/qa/QARepositoryFake";
import QARepositoryDev from "./data/repositories/qa/QARepositoryDev";
import QARepositoryImpl from "./data/repositories/qa/QARepositoryImpl";
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from "./domain/use_cases/qa/SearchAgentsUseCase";
import GetAgentByIdUseCase, { GetAgentByIdUseCaseName } from "./domain/use_cases/qa/GetAgentByIdUseCase";
import DeleteAgentUseCase, { DeleteAgentUseCaseName } from "./domain/use_cases/qa/DeleteAgentUseCase";
import CreateAgentUseCase, { CreateAgentUseCaseName } from "./domain/use_cases/qa/CreateAgentUseCase";
import UpdateAgentUseCase, { UpdateAgentUseCaseName } from "./domain/use_cases/qa/UpdateAgentUseCase";
import CheckAudioWithQuestionsUseCase, { CheckAudioWithQuestionsUseCaseName } from "./domain/use_cases/qa/CheckAudioWithQuestionsUseCase";
import UpdateQuestionForAgentUseCase, { UpdateQuestionForAgentUseCaseName } from "./domain/use_cases/qa/UpdateQuestionForAgentUseCase";
import CreateQuestionForAgentUseCase, { CreateQuestionForAgentUseCaseName } from "./domain/use_cases/qa/CreateQuestionForAgentUseCase";
import DeleteQuestionFromAgentUseCase, { DeleteQuestionFromAgentUseCaseName } from "./domain/use_cases/qa/DeleteQuestionFromAgentUseCase";
import GetClientsListUseCase, { GetClientsListUseCaseName } from "./domain/use_cases/dashboard/GetClientsListUseCase";
import DashboardRepository, { DashboardRepositoryName } from "./domain/repositories/DashboardRepository";
import GetOperationReportUseCase, { GetOperationReportUseCaseName } from "./domain/use_cases/dashboard/GetOperationReportUseCase";
import DashboardRepositoryFake from "./data/repositories/dashboard/DashboardRepositoryFake";
import DashboardRepositoryDev from "./data/repositories/dashboard/DashboardRepositoryDev";
import DashboardRepositoryImpl from "./data/repositories/dashboard/DashboardRepositoryImpl";

enum MODE_DI { PRODUCTION, DEVELOPMENT, TEST, FAKE }

let mode = MODE_DI.DEVELOPMENT.toString();
const di = new Container();
//#region ------------------ CONSTANTS ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsFake);
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsDev);
} else {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsImpl);
}

di.bind<HostApi>(HostApiName).to(HostApi).inSingletonScope();
//#endregion ------------------ CONSTANTS ------------------ 0//

//#region ------------------ REPOSITORIES ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryFake).inSingletonScope();
    di.bind<DashboardRepository>(DashboardRepositoryName).to(DashboardRepositoryFake).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryFake).inSingletonScope();
    di.bind<QARepository>(QARepositoryName).to(QARepositoryFake).inSingletonScope();
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryDev).inSingletonScope();
    di.bind<DashboardRepository>(DashboardRepositoryName).to(DashboardRepositoryDev).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryDev).inSingletonScope();
    di.bind<QARepository>(QARepositoryName).to(QARepositoryDev).inSingletonScope();
} else {
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryImpl).inSingletonScope();
    di.bind<DashboardRepository>(DashboardRepositoryName).to(DashboardRepositoryImpl).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryDev).inSingletonScope();
    di.bind<QARepository>(QARepositoryName).to(QARepositoryImpl).inSingletonScope();
}
//#endregion ------------------ REPOSITORIES ------------------ //

//#region ------------------ PROVIDERS ------------------ //   
di.bind<LocalizationProvider>(LocalizationProviderName).toConstantValue(LocalizationProviderImpl);
di.bind<ModalsProvider>(ModalsProviderName).toConstantValue(ModalsProviderImpl);
di.bind<UserProvider>(UserProviderName).toConstantValue(UserProviderImpl);
//#endregion ------------------ PROVIDERS ------------------ //   


//#region ------------------ USE CASES ------------------//
//#region ------------------ Authentication ------------------ //
di.bind<GetCurrentUserUseCase>(GetCurrentUserUseCaseName).toDynamicValue((context) => {
    return new GetCurrentUserUseCase({
        authRepository: context.container.get<AuthRepository>(AuthRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName)
    });
}).inSingletonScope();
di.bind<LoginWithEmailAndPasswordUseCase>(LoginWithEmailAndPasswordUseCaseName).toDynamicValue((context) => {
    return new LoginWithEmailAndPasswordUseCase({
        authRepository: context.container.get<AuthRepository>(AuthRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName)
    });
}).inSingletonScope();
di.bind<LogoutUseCase>(LogoutUseCaseName).toDynamicValue((context) => {
    return new LogoutUseCase({
        authRepository: context.container.get<AuthRepository>(AuthRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Authentication ------------------ //
//#region ------------------ Dashboard ------------------ //
di.bind<GetClientsListUseCase>(GetClientsListUseCaseName).toDynamicValue((context) => {
    return new GetClientsListUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
di.bind<GetOperationReportUseCase>(GetOperationReportUseCaseName).toDynamicValue((context) => {
    return new GetOperationReportUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
//#endregion ------------------ Dashboard ------------------ //
//#region ------------------ Default ------------------ //
di.bind<LoadUseCase>(LoadUseCaseName).toDynamicValue((context) => {
    return new LoadUseCase({
    });
}).inSingletonScope();
//#endregion ------------------ Default ------------------ //
//#region ------------------ Localization ------------------ //
di.bind<GetAvailableLanguagesUseCase>(GetAvailableLanguagesUseCaseName).toDynamicValue((context) => {
    return new GetAvailableLanguagesUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<GetCurrentLanguageUseCase>(GetCurrentLanguageUseCaseName).toDynamicValue((context) => {
    return new GetCurrentLanguageUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<SetCurrentLanguageUseCase>(SetCurrentLanguageUseCaseName).toDynamicValue((context) => {
    return new SetCurrentLanguageUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Localization ------------------ //
//#region ------------------ QA ------------------ //
di.bind<CheckAudioWithQuestionsUseCase>(CheckAudioWithQuestionsUseCaseName).toDynamicValue((context) => {
    return new CheckAudioWithQuestionsUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<CreateAgentUseCase>(CreateAgentUseCaseName).toDynamicValue((context) => {
    return new CreateAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<CreateQuestionForAgentUseCase>(CreateQuestionForAgentUseCaseName).toDynamicValue((context) => {
    return new CreateQuestionForAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<DeleteAgentUseCase>(DeleteAgentUseCaseName).toDynamicValue((context) => {
    return new DeleteAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<DeleteQuestionFromAgentUseCase>(DeleteQuestionFromAgentUseCaseName).toDynamicValue((context) => {
    return new DeleteQuestionFromAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<GetAgentByIdUseCase>(GetAgentByIdUseCaseName).toDynamicValue((context) => {
    return new GetAgentByIdUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<SearchAgentsUseCase>(SearchAgentsUseCaseName).toDynamicValue((context) => {
    return new SearchAgentsUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<UpdateAgentUseCase>(UpdateAgentUseCaseName).toDynamicValue((context) => {
    return new UpdateAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<UpdateQuestionForAgentUseCase>(UpdateQuestionForAgentUseCaseName).toDynamicValue((context) => {
    return new UpdateQuestionForAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
//#endregion ------------------ QA ------------------ //
//#endregion ------------------ USE CASES ------------------//

export default di;