import { Either, right, left } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";

const UploadAudioFileApiImpl = async (file: File, urlFetch: string): Promise<Either<ErrorEntity, void>> => {
    try {
        const response = await fetch(urlFetch, {
            method: 'PUT',
            headers: {
                'Content-Type': 'audio/mpeg',
            },
            body: file
        });
        console.log("UploadAudioFileApiImpl response", response);
        return right(undefined);
    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default UploadAudioFileApiImpl;