import './ResultIAAudioComponent.scss';
import { FC, useState } from 'react';
import ResultIAAudioComponentProps from './ResultIAAudioComponentProps';

const ResultIAAudioComponent: FC<ResultIAAudioComponentProps> = ({ result }) => {
    const [expandeds, setExpandeds] = useState<string[]>([]);
    const _isExpanded = (index: string) => expandeds.includes(index);

    const _toggleExpanded = (index: string) => {
        if (_isExpanded(index)) setExpandeds(expandeds.filter(expanded => expanded !== index));
        else setExpandeds([...expandeds, index]);
    }

    const calculateScore = (): number => {
        const acc = result.responses.reduce((acc, response) => acc + response.response.points, 0);
        const prom = acc / result.responses.length;
        return parseFloat(prom.toFixed(2));
    }

    return <div className="result_ia_audio_component">
        <h5>Result</h5>
        <div className="result">
            <div className="row">
                <div className="col-md-10">
                    <audio controls src={result.audioUrl} />
                </div>
                <div className="col-md-2">
                    <div className="score d-flex">
                        <h6>Score</h6>
                        <h1>{calculateScore()}</h1>
                    </div>
                </div>
            </div>
            {result.responses.map((response, index) => <div key={index} className="">
                <div className="question">
                    <h6>{response.question.title} ({response.question.points} Points)</h6>
                    {response.question.description.length > 100 && !_isExpanded(index + "q") ? `${response.question.description.substring(0, 100)}...` : response.question.description}
                    {response.question.description.length > 100 && <span className='more_text' onClick={() => _toggleExpanded(index + "q")}>{_isExpanded(index + "q") ? "Hide" : "Show"} more</span>}
                </div>
                <div className='response'>
                    <strong>{response.response.short} ({response.response.points}   Points)</strong>
                    <p>{response.response.justification}</p>
                </div>
            </div>)}
        </div>
    </div>
}

export default ResultIAAudioComponent;