import { injectable } from "inversify";
import QARepository from "../../../domain/repositories/QARepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import QAAgentEntity from "../../../domain/entities/QAAgentEntity";
import QAQuestionEntity from "../../../domain/entities/QAQuestionEntity";
import QAResultEntity from "../../../domain/entities/QAResultEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import SearchAgentsApiImpl from "./api/SearchAgentsApiImpl";
import GetAgentByIdApiImpl from "./api/GetAgentByIdApiImpl";
import UpdateQuestionApiImpl from "./api/UpdateQuestionApiImpl";
import CreateQuestionApiImpl from "./api/CreateQuestionApiImpl";
import CreateAgentApiImpl from "./api/CreateAgentApiImpl";
import DeleteQuestionApiImpl from "./api/DeleteQuestionApiImpl";
import UploadAudioForTranscriptionApiImpl from "./api/UploadAudioForTranscriptionApiImpl";
import GetAudioResultsApiImpl from "./api/GetAudioResultsApiImpl";
import DeleteAgentApiImpl from "./api/DeleteAgentApiImpl";

@injectable()
class QARepositoryImpl implements QARepository {
    updateAgent(agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> {
        throw new Error("Method not implemented.");
    }
    deleteAgent = (id: string): Promise<Either<ErrorEntity, void>> => DeleteAgentApiImpl(id);
    getAudioResults = async (jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>> => GetAudioResultsApiImpl(jobId, onCallBack);
    uploadAudioForTranscription = async (audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>> => UploadAudioForTranscriptionApiImpl(audio, agentId, onCallBack);
    createAgent = async (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => CreateAgentApiImpl(agent);
    searchAgents = (query: string, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => SearchAgentsApiImpl(query, page, itemsPerPage);
    getAgentById = async (id: string): Promise<Either<ErrorEntity, QAAgentEntity>> => GetAgentByIdApiImpl(id);
    addQuestionToAgent = async (agentId: string, question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => CreateQuestionApiImpl(question);
    deleteQuestionFromAgent = async (questionId: string): Promise<Either<ErrorEntity, void>> => DeleteQuestionApiImpl(questionId);
    updateQuestionFromAgent = (question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => UpdateQuestionApiImpl(question);

}

export default QARepositoryImpl;