import "./ModalCustomComponentStyles.css";
import React, { useContext } from "react";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import ModalCustomComponentProps from "./ModalCustomComponentProps";
import ModalComponent from "../modalItem/ModalComponent";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import ModalsContext from "../../ModalsContext";
import LocalizationContext from "../../../localization/LocalizationContext";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";

const ModalCustomComponent: React.FC<ModalCustomComponentProps> = () => {
    const { isOpenModalCustom, closeModalCustom, customModal, } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    if (customModal == null) return <></>

    return <div className="modal_custom_component">
        <ModalComponent title={customModal?.title ?? ""} hideOnClick={customModal?.params?.hideOnTap ?? true} centered size={customModal.size ?? 'lg'} isOpen={isOpenModalCustom} toggle={() => closeModalCustom()} >
            <div>
                {customModal.content}
            </div>
            {customModal?.params?.closeButton && <div className="modal_footer d-flex justify-content-end">
                <button onClick={closeModalCustom} className="finish_modal_container_button">
                    {i18n(KeyWordLocalization.ModalCustomCompoonentCloseButton)}
                </button>
            </div>}
        </ModalComponent>
    </div>
}

export default ModalCustomComponent;