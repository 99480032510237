import { FC } from "react";
import CardFeelingComponentProps from "./CardFeelingComponentProps";

const CardFeelingComponent: FC<CardFeelingComponentProps> = ({ title, color, total, icon, percentage, className }) => {
    return <div className={`card_feeling_component ${className}`} style={{ color: color }}>
        <div className="card_content_feeling h-100">
            <div className="d-flex align-items-end"><h5>{total}</h5>{percentage && <span className="percentage ms-2 mb-1">({percentage}%)</span>}</div>
            <p className="d-flex align-items-center">{icon} {title}</p>
        </div>
    </div>
}

export default CardFeelingComponent;