import './ModalComponentStyles.css';
import { FC } from "react";
import ModalComponentProps from "./ModalComponentProps";
import { MdClose } from 'react-icons/md';

const ModalComponent: FC<ModalComponentProps> = ({ children, isOpen, title, toggle, size, hideOnClick = true }) => {
    return <div className="modal_component">
        <div className="back_modal" onClick={toggle}></div>
        <div className={`modal_content ${size}`}>
            <div className="modal_header">
                <div className="modal_title">
                    {title}
                </div>
                <div className="modal_close" onClick={toggle}>
                    <MdClose />
                </div>
            </div>
            <hr />
            <div className="modal_body">
                {children}
            </div>
        </div>
    </div>
}

export default ModalComponent;