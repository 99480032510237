import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import QAQuestionEntity from "../../../../domain/entities/QAQuestionEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAQuestionDto from "../../../dto/QAQuestionDto";

const CreateQuestionApiImpl = async (agent: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
    console.log('create question api', agent);
    try {
        const relativeUrl = "/question";
        const body = QAQuestionDto.toJSON(agent);
        //remove id from bod
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, { contentType: 'application/json' });
        return right(QAQuestionDto.fromJSON(response));
    } catch (error) {
        console.log('error', error);
        return left(ErrorEntityFromError(error));
    }
}

export default CreateQuestionApiImpl;