import { injectable } from "inversify";
import DashboardRepository, { AverageCalls, Client, GetOperationReportResponse, ReasonForCalls, SentimentsOnDate, Target } from "../../../domain/repositories/DashboardRepository";
import DashboardRepositoryFake from "./DashboardRepositoryFake";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import MasterItemEntity from "../../../domain/entities/MasterItemEntity";
import GetCallAveragesApiImpl from "./api/GetCallAveragesApiImpl";
import GetDayToDayApiImpl from "./api/GetDayToDayApiImpl";

@injectable()
class DashboardRepositoryDev implements DashboardRepository {
    fakeRepository = new DashboardRepositoryFake();
    getCallAverages= (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, { averages: AverageCalls; totalCalls: number; }>> => GetCallAveragesApiImpl(clientId, startDate, endDate);
    getSentiments = (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, SentimentsOnDate[]>> => GetDayToDayApiImpl(clientId, startDate, endDate);
    getReasonForCalls = (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, ReasonForCalls[]>> => this.fakeRepository.getReasonForCalls(clientId, startDate, endDate);
    getScoreDetails = (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, Target>> => this.fakeRepository.getScoreDetails(clientId, startDate, endDate);
    getClientOps = (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, { best: Client[]; worst: Client[]; }>> => this.fakeRepository.getClientOps(clientId, startDate, endDate);
    getClientsList = (): Promise<Either<ErrorEntity, MasterItemEntity[]>> => this.fakeRepository.getClientsList();
}

export default DashboardRepositoryDev;