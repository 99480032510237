import './LoadingComponent.css';
import { FC, useContext } from 'react';
import LoadingComponentProps from './LoadingComponentProps';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import LocalizationContext from '../../providers/localization/LocalizationContext';
import KeyWordLocalization from '../../utils/KeyWordLocalization';


const LoadingComponent: FC<LoadingComponentProps> = ({ showLogo = false, fullScreen = false }) => {
  const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
  return <div className={`loading_component ${fullScreen && 'full_screen'}`}>
    {showLogo && <img className='logo' src='/assets/logo/long_gray.png' alt='logo' />}
    <div className='loading' />
    <strong>{i18n(KeyWordLocalization.LoadingComponentLoading)}</strong>
  </div>

}

export default LoadingComponent;
