import { injectable } from "inversify";
import DashboardRepository, { AverageCalls, Client, GetOperationReportResponse, ReasonForCalls, SentimentsOnDate, Target } from "../../../domain/repositories/DashboardRepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import MasterItemEntity from "../../../domain/entities/MasterItemEntity";
import GetCallAveragesApiImpl from "./api/GetCallAveragesApiImpl";
import GetDayToDayApiImpl from "./api/GetDayToDayApiImpl";

@injectable()
class DashboardRepositoryImpl implements DashboardRepository {
    getCallAverages= (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, { averages: AverageCalls; totalCalls: number; }>> => GetCallAveragesApiImpl(clientId, startDate, endDate);
    getSentiments = (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, SentimentsOnDate[]>> => GetDayToDayApiImpl(clientId, startDate, endDate);
    getReasonForCalls= (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, ReasonForCalls[]>> => {
        throw new Error("Method not implemented.");
    }
    getScoreDetails= (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, Target>> => {
        throw new Error("Method not implemented.");
    }
    getClientOps= (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, { best: Client[]; worst: Client[]; }>> => {
        throw new Error("Method not implemented.");
    }
    getOperationReport = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, GetOperationReportResponse>> => {
        throw new Error("Method not implemented.");
    }
    getClientsList = async (): Promise<Either<ErrorEntity, MasterItemEntity[]>> => {
        throw new Error("Method not implemented.");
    }
}

export default DashboardRepositoryImpl;