import { useNavigate } from 'react-router-dom';
import './PaginatorComponentStyles.scss';
import { FC, useContext, useState } from "react";
import PaginatorComponentProps from './PaginatorComponentProps';
import LocalizationContext from '../../providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../utils/KeyWordLocalization';

const PaginatorComponent: FC<PaginatorComponentProps> = ({ resultData, onChangePage }) => {
    const limitOfShow = window.innerWidth < 768 ? 3 : 5;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const _getArrayToShow = (): number[] => {
        console.log("array to show", { a: resultData.pages <= limitOfShow, b: resultData.page <= 3, c: resultData.page >= resultData.pages - (Math.floor(limitOfShow / 2)) });
        if (resultData.pages <= limitOfShow) return Array(resultData.pages).fill(0).map((e, index) => index + 1);
        else if (resultData.page <= 3) return Array(resultData.pages < limitOfShow ? resultData.pages : limitOfShow + 1).fill(0).map((e, index) => index + 1);
        else if (resultData.page >= resultData.pages - (Math.floor(limitOfShow / 2))) return Array(resultData.pages < limitOfShow ? resultData.pages : limitOfShow).fill(0).map((e, index) => resultData.pages - limitOfShow + index + 1);
        else return Array(limitOfShow).fill(0).map((e, index) => resultData.page - (Math.floor(limitOfShow / 2)) + index);
    }

    if (resultData == null || resultData.total == 0) return <></>

    return <div className="paginator_component">
        <div className="w-100 text-center">
            {i18n(KeyWordLocalization.PaginatorComponentShowing, {
                showing: resultData.data.length,
                total: resultData.total,
            })}
        </div>
        <div className="d-flex justify-content-center">
            {resultData.pages > 1 ? <div className="d-flex mb-5 mt-3">
                <div className={"o-option-paginator" + (resultData.page == 1 ? " disabled" : "")} onClick={() => onChangePage(resultData.page - 1)}><span className="material-symbols-outlined">chevron_left</span></div>
                {resultData.page != 1 && resultData.pages > limitOfShow && resultData.page > Math.ceil(limitOfShow / 2) + 1 ? <><div className="o-option-paginator" onClick={() => onChangePage(1)}>1</div> <div className="o-option-paginator">...</div> </> : ''}
                {_getArrayToShow().map((e, i) => <div key={e} className={"o-option-paginator" + (resultData.page == e ? " active" : "")} onClick={() => onChangePage(e)}>{e}</div>)}
                {resultData.pages != resultData.page &&  resultData.pages > limitOfShow && resultData.page < resultData.pages - Math.floor(limitOfShow / 2) ? <><div className="o-option-paginator">...</div> <div className="o-option-paginator" onClick={() => onChangePage(resultData.pages)}>{resultData.pages}</div></> : ''}
                <div className={"o-option-paginator" + (resultData.page == resultData.pages ? " disabled" : "")} onClick={() => onChangePage(resultData.page + 1)}><span className="material-symbols-outlined">chevron_right</span></div>
            </div> : ''}
        </div>
    </div>
}

export default PaginatorComponent;