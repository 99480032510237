import QAResultEntity from "../../domain/entities/QAResultEntity";

const fromJSON = (json: any): QAResultEntity => {
    console.log("json qa result", json);
    return {
        id: json.id ?? "",
        audioUrl: json.output_result?.audios?.conversation_audio ?? "",
        responses: json.output_result.analysis.customs.map((custom: any) => {
            return {
                question: {
                    title: custom.title,
                    description: custom.question,
                    points: custom.points,
                },
                response: {
                    short: custom.response.answer,
                    justification: custom.response.justification,
                    points: custom.response.score
                }
            }
        }),
    }
}


export default {
    fromJSON,
};