import "./AudioZoneComponent.scss";
import { createRef, FC, useContext, useState } from "react";
import AudioZoneComponentProps from "./AudioZoneComponentProps";
import ButtonComponent from "../../../../../components/button/ButtonComponent";
import di from "../../../../../../DependencyInjection";
import ProgressBarComponent from "../../../../../components/progressBar/ProgressBarComponent";
import CheckAudioWithQuestionsUseCase, { CheckAudioWithQuestionsUseCaseName } from "../../../../../../domain/use_cases/qa/CheckAudioWithQuestionsUseCase";
import { isLeft } from "fp-ts/lib/Either";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";
import ModalsContext from "../../../../../providers/modal/ModalsContext";
import KeyWordLocalization from "../../../../../utils/KeyWordLocalization";
import ResultIAAudioComponent from "../resultQuestion/ResultIAAudioComponent";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../../../providers/localization/LocalizationContext";
import QAResultEntity from "../../../../../../domain/entities/QAResultEntity";
import { CircularProgressbar } from 'react-circular-progressbar';


const AudioZoneComponent: FC<AudioZoneComponentProps> = ({ qaAgent }) => {
    const { addToast, openModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [audioProcesing, setAudioProcessing] = useState<{ audioName: string, randomId: string, message: string, progress: number, error?: boolean, result?: QAResultEntity }[]>([]);
    const [dragging, setDragging] = useState(false);
    const inputRef = createRef<HTMLInputElement>();
    const audioCall: { audioName: string, randomId: string, message: string, progress: number, error?: boolean, result?: QAResultEntity }[] = [];

    const _handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        // event.preventDefault();
        setDragging(false);
    }

    const _handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        setDragging(true);
        event.preventDefault();
        event.stopPropagation();
    }

    const _handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    };

    const _updateAudioProcessing = (nameFile: string, randomId: string, progress: number, message: string, error?: boolean, result?: QAResultEntity | undefined) => {
        const copyAudios = [...audioCall];
        console.log('audio pr', { nameFile, ad: copyAudios.map(a => a.audioName) });
        const edited = copyAudios.find((audio) => audio.randomId == randomId);
        if (edited == undefined) {
            copyAudios.push({ audioName: nameFile, randomId, message, progress, error, result });
            audioCall.push({ audioName: nameFile, randomId, message, progress, error, result });
            setAudioProcessing(copyAudios);
        } else {
            edited.message = message;
            edited.progress = progress;
            edited.error = error;
            edited.result = result;
            setAudioProcessing(copyAudios);
        }
        console.log('audio', { nameFile, ad: copyAudios.map(a => a.audioName) });
    }


    const _handleSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files == null || event.target.files.length == 0) return;
        const files = event.target.files;

        const addAudio = async (file: File) => {
            const randomId = Math.random().toString(36).substring(7);
            const audioName = file.name ?? "";
            _updateAudioProcessing(audioName, randomId, 0, i18n(KeyWordLocalization.CheckAudioWithQuestionsUseCaseProcessingPetiton), false, undefined);
            const response = await di.get<CheckAudioWithQuestionsUseCase>(CheckAudioWithQuestionsUseCaseName).call(file, qaAgent,
                (progress, text) => _updateAudioProcessing(audioName, randomId, progress, text));
            console.log('response CheckAudioWithQuestionsUseCase', response);
            if (isLeft(response)) {
                _updateAudioProcessing(audioName, randomId, 0, i18n(response.left.code ?? KeyWordLocalization.CheckAudioWithQuestionsUseCaseProcessingPetiton), true, undefined);
                event.target.value = "";
                return addToast(response.left.code ?? KeyWordLocalization.UnknownError, "error", undefined);
            }
            addToast(i18n(KeyWordLocalization.AudioZoneComponentAudioUploaded), "success", undefined);
            _updateAudioProcessing(audioName, randomId, 100, i18n(KeyWordLocalization.CheckAudioWithQuestionsUseCaseDone), false, response.right);
        }

        for (let i = 0; i < files.length; i++) {
            addAudio(files[i]);
        }

        event.target.value = "";
    }

    const _handleOpenResults = async (result?: QAResultEntity) => {
        if (result == undefined) return;
        openModalCustom("lg", i18n(KeyWordLocalization.AudioZoneComponentResultTitle), <ResultIAAudioComponent result={result} />);
    }

    return <div className="audio_zone_component" onDragOver={_handleDragOver} onDrop={_handleDrop} onDragLeave={_handleDragLeave} >
        <h3>{i18n(KeyWordLocalization.AudioZoneComponentTitle)}</h3>
        <div className="drop_zone">
            <input type="file" multiple accept="audio/*" onChange={_handleSelectFile} ref={inputRef} />
            {dragging && <div className="dragging">
                <div className="pulse">
                    <span className="material-symbols-outlined">cloud_upload</span>
                    {i18n(KeyWordLocalization.AudioZoneComponentDropHere)}
                </div>
            </div>}
            <div className="description">
                <div className="mb-2">
                    {i18n(KeyWordLocalization.AudioZoneComponentJustDrop)}
                </div><ButtonComponent text="Select a file" />
            </div>
        </div>
        {audioProcesing.map((audio, index) => <div className="item_loading my-3 hover" onClick={() => _handleOpenResults(audio.result)}>
            <div className="d-flex align-items-center">
                <div className="d-flex flex-grow-1 flex-column overflow-hidden">
                    <div className="text_ellipsis">
                        <strong>{audio.audioName}</strong>
                    </div>
                    <div className="w-100 d-flex justify-content-start text_ellipsis">
                        {audio.error == true && <div className="text-danger" role="alert">{i18n(audio.message)}</div>}
                        {audio.result && <div className="text-success" role="alert">{i18n(audio.message)}</div>}
                        {!audio.error && !audio.result && <span>{i18n(audio.message)}</span>}
                    </div>
                </div>
                <div className="ms-2">
                    {audio.error && <span className="material-symbols-outlined text-danger">error</span>}
                    {audio.result && <span className="material-symbols-outlined text-success">check</span>}
                    {!audio.error && !audio.result && <div style={{ width: '1.5em' }}>
                        <CircularProgressbar value={audio.progress} />
                    </div>
                    }
                </div>
            </div>
        </div>)}

    </div>
}

export default AudioZoneComponent;