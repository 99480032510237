import { injectable } from "inversify";
import LocalizationEntity from "../../entities/LocalizationEntity";
import LocalizationRepository from "../../repositories/LocalizationRepository";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";

interface props {
    localizationRepository: LocalizationRepository;
    localizationProvider: LocalizationProvider;
}
@injectable()
class SetCurrentLanguageUseCase {
    _localizationRepository: LocalizationRepository;
    _localizationProvider: LocalizationProvider;

    constructor(_: props) {
        this._localizationRepository = _.localizationRepository;
        this._localizationProvider = _.localizationProvider;
    }
    public call = async (localization: LocalizationEntity): Promise<void> => {
        await this._localizationRepository.changeLanguage(localization);
        this._localizationProvider.Actions.setLocalization(localization);
    }
}

export default SetCurrentLanguageUseCase;

export const SetCurrentLanguageUseCaseName = "SetCurrentLanguageUseCase";