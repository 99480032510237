import { Either, left, right } from "fp-ts/lib/Either";
import QAAgentEntity from "../../../../domain/entities/QAAgentEntity";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import QAQuestionDto from "../../../dto/QAQuestionDto";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const CreateAgentApiImpl = async (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => {
    try {
        const relativeUrl = "/agent";
        const body = {
            "name": agent.name,
            "questions": agent.questions.map(q => QAQuestionDto.toJSON(q)),
        };
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, {
            contentType: "application/json",
        });

        console.log("CreateAgentApiImpl response", response);
        const parsed:QAAgentEntity = {
            client: "",
            id: response.id,
            name: response.name,
            opType: "",
            questions: [],
        }
        return right(parsed);
    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default CreateAgentApiImpl;