import './App.scss';
import 'react-circular-progressbar/dist/styles.css';
import di from './DependencyInjection';
import LocalizationProvider, { LocalizationProviderName } from './domain/providers/localization/LocalizationProvider';
import ModalsProvider, { ModalsProviderName } from './domain/providers/modal/ModalsProvider';
import UserProvider, { UserProviderName } from './domain/providers/user/UserProvider';
import RoutesComponent from './presentation/routes/RoutesComponent';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);

const App = () => {
  const localizationProvider = di.get<LocalizationProvider>(LocalizationProviderName);
  const modalsProvider = di.get<ModalsProvider>(ModalsProviderName);
  const userProvider = di.get<UserProvider>(UserProviderName);
  return <>
      <localizationProvider.Provider>
        <modalsProvider.Provider>
              <userProvider.Provider>
                <RoutesComponent />
              </userProvider.Provider>
        </modalsProvider.Provider>
      </localizationProvider.Provider>
  </>

}

export default App;
