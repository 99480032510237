import { FC, useContext } from "react";
import LayoutSideBarComponentProps from "./LayoutSideBarComponentProps";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../routes/Routes";
import di from "../../../../../DependencyInjection";
import LogoutUseCase, { LogoutUseCaseName } from "../../../../../domain/use_cases/auth/LogoutUseCase";
import UserContext from "../../../../providers/user/UserContext";
import UserContextType from "../../../../../domain/providers/user/UserContextType";
import LocalizationContext from "../../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";

const _SideBarOption: FC<{ icon: React.ReactElement, title: string, active?: boolean, onClick?: () => void }> = ({ icon, title, active, onClick }) => {
    return <div className={`sidebar_option ${onClick && 'hover'} ${active && 'active'}`} onClick={onClick}>
        <div className="sidebar_option_icon">
            {icon}
        </div>
        <div className="sidebar_option_text">{title}</div>
    </div>
}

const LayoutSideBarComponent: FC<LayoutSideBarComponentProps> = ({ open, setOpen }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useContext(UserContext) as UserContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const _handleLogout = async () => {
        await di.get<LogoutUseCase>(LogoutUseCaseName).call();
        navigate(routes.login.relativePath);
    }

    const routesToUse = [
        {
            route: routes.home,
            icon: <span className="material-symbols-outlined">dashboard</span>,
        },
        {
            route: routes.agent_list,
            icon: <span className="material-symbols-outlined">people</span>,
        },
    ]

    const isSamePath = (path: string) => location.pathname === path;
    return <div className="layout_sidebar_component">
        {open && <div className="bg_drop" onClick={() => setOpen(false)}></div>}
        <div className="content_sidebar">
            <div className="flex-grow-1">
                <div className="img_icon">
                    <img src="/assets/logo/b_yellow.png" className="closed" alt="" />
                    <img src="/assets/logo/long_gray.png" alt="" className="open"/>
                </div>
                {routesToUse.filter(route => route.route.auth(user)).map(route =>
                    <_SideBarOption key={route.route.relativePath}
                        icon={route.icon}
                        active={isSamePath(route.route.relativePath)}
                        onClick={() => navigate(route.route.relativePath)}
                        title={i18n(route.route.title ?? "")}
                    />
                )}
            </div>
            <_SideBarOption icon={<span className="material-symbols-outlined">logout</span>} onClick={_handleLogout} title="Logout" />
        </div>
    </div>
}

export default LayoutSideBarComponent;