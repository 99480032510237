import { injectable } from "inversify";
import QARepository from "../../repositories/QARepository";
import QAAgentEntity from "../../entities/QAAgentEntity";
import { Either, isLeft } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";
import SearchResultEntity from "../../entities/SearchResultEntity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class SearchAgentsUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (query: string | undefined, page: number, itemsPerPage: number) : Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => {
        return this.qaRepository.searchAgents(query, page, itemsPerPage);
    }
}

export const SearchAgentsUseCaseName = "SearchAgentsUseCase";