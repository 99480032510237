const KeyWordLocalization = {
    TRADE_MARK: 'TRADE_MARK',
    edit: "edit",
    Search: "Search",

    //#region---------------------- Pages ----------------------//
    SignInPageSuccess: "SignInPageSuccess",
    SignInPageTitle: "SignInPageTitle",
    SignInPageSubtitle: "SignInPageSubtitle",
    SignInPageEmail: "SignInPageEmail",
    SignInPagePassword: "SignInPagePassword",
    SignInPageButtonSignIn: "SignInPageButtonSignIn",

    AgentListTitle: "AgentListTitle",
    AgentListPageQuery: "AgentListPageQuery",
    AgentListCurrentPage: "AgentListCurrentPage",
    AgentListPageSearchInput: "AgentListPageSearchInput",
    AgentListTotalPages: "AgentListTotalPages",
    AgentListPageSearch: "AgentListPageSearch",
    AgentListPageId: "AgentListPageId",
    AgentListPageName: "AgentListPageName",
    AgentListPageActions: "AgentListPageActions",
    AgentListPageActionEdit: "AgentListPageActionEdit",
    AgentListPageActionDelete: "AgentListPageActionDelete",
    AgentListPageAddAgent: "AgentListPageAddAgent",
    AgentListPageDeleteSuccess: "AgentListPageDeleteSuccess",
    AgentListDeleteModalTitle: "AgentListDeleteModalTitle",
    AgentListDeleteModalSubtitle: "AgentListDeleteModalSubtitle",

    CreateAgentPageTitle: "CreateAgentPageTitle",
    CreateAgentPageAddQuestion: "CreateAgentPageAddQuestion",
    CreateAgentPageCreateError: "CreateAgentPageCreateError",
    CreateAgentPageCreateSuccess: "CreateAgentPageCreateSuccess",
    CreateAgentPageQuestionAdded: "CreateAgentPageQuestionAdded",
    CreateAgentPageCreateQuestion: "CreateAgentPageCreateQuestion",
    CreateAgentPageQuestionUpdated: "CreateAgentPageQuestionUpdated",
    CreateAgentPageEditQuestion: "CreateAgentPageEditQuestion",
    CreateAgentPageEditTitle: "CreateAgentPageEditTitle",
    CreateAgentPageAddTitle: "CreateAgentPageAddTitle",
    CreateAgentPageQuestionsTitle: "CreateAgentPageQuestionsTitle",
    CreateAgentPageNoQuestions: "CreateAgentPageNoQuestions",
    CreateAgentPageQuestionPoints: "CreateAgentPageQuestionPoints",
    CreateAgentPageDelete: "CreateAgentPageDelete",
    CreateAgentPageEdit: "CreateAgentPageEdit",
    CreateAgentUpdate: "CreateAgentUpdate",
    CreateAgentCreate: "CreateAgentCreate",
    CreateAgentPageUpdateError: "CreateAgentPageUpdateError",
    CreateAgentPageUpdateSuccess: "CreateAgentPageUpdateSuccess",
    CreateAgentPageQuestionDeleted: "CreateAgentPageQuestionDeleted",
    CreateAgentPageDeleteQuestionTitle: "CreateAgentPageDeleteQuestionTitle",
    CreateAgentPageDeleteQuestionSubtitle: "CreateAgentPageDeleteQuestionSubtitle",
    CreateAgentPageQuestionError: "CreateAgentPageQuestionError",

    DetailedAgentPageTitle: "DetailedAgentPageTitle",
    DetailedAgentPageQuestions: "DetailedAgentPageQuestions",
    DetailedAgentPagePoints: "DetailedAgentPagePoints",

    DashboardPageOpTypeForm: "DashboardPageOpTypeForm",
    DashboardPageDateFromForm: "DashboardPageDateFromForm",
    DashboardPageDateToForm: "DashboardPageDateToForm",
    DashboardPageSentimentNegative: "DashboardPageSentimentNegative",
    DashboardPageSentimentNeutral: "DashboardPageSentimentNeutral",
    DashboardPageSentimentPositive: "DashboardPageSentimentPositive",
    DashboardPageUrlParamOptype: "DashboardPageUrlParamOptype",
    DashboardPageUrlParamDateFrom: "DashboardPageUrlParamDateFrom",
    DashboardPageUrlParamDateTo: "DashboardPageUrlParamDateTo",
    DashboardPageTotalCalls: "DashboardPageTotalCalls",
    DashboardPageAverageCallScore: "DashboardPageAverageCallScore",
    DashboardPageAverageAgentScore: "DashboardPageAverageAgentScore",
    DashboardPageAverageCustomerScore: "DashboardPageAverageCustomerScore",
    DashboardPageSentimentsOnTime: "DashboardPageSentimentsOnTime",
    DashboardPageReasonsForCall: "DashboardPageReasonsForCall",
    DashboardPageBestClients: "DashboardPageBestClients",
    DashboardPageWorstClients: "DashboardPageWorstClients",
    //#endregion---------------------- Pages ----------------------//

    //#region---------------------- Components ----------------------//
    ModalCustomCompoonentCloseButton: "ModalCustomCompoonentCloseButton",

    LoadingComponentLoading: "LoadingComponentLoading",

    AuthIfonComponentRevolutionTitle: "AuthIfonComponentRevolutionTitle",
    AuthIfonComponentRevolutionSubitle: "AuthIfonComponentRevolutionSubitle",
    AuthIfonComponentAIPoweredTitle: "AuthIfonComponentAIPoweredTitle",
    AuthIfonComponentAIPoweredSubitle: "AuthIfonComponentAIPoweredSubitle",
    AuthIfonComponentFastTitle: "AuthIfonComponentFastTitle",
    AuthIfonComponentFastSubtitle: "AuthIfonComponentFastSubtitle",
    AuthIfonComponentBoostTitle: "AuthIfonComponentBoostTitle",
    AuthIfonComponentBoostSubtitle: "AuthIfonComponentBoostSubtitle",

    PaginatorComponentShowing: "PaginatorComponentShowing", //showing, total

    DeleteModalComponentConfirm: "DeleteModalComponentConfirm",
    DeleteModalComponentCancel: "DeleteModalComponentCancel",
    DeleteModalComponentDelete: "DeleteModalComponentDelete",
    DeleteModalComponentMessage: "DeleteModalComponentMessage",
    DeleteModalComponentError: "DeleteModalComponentError",

    CreateQuestionComponentDescription: "CreateQuestionComponentDescription",
    CreateQuestionComponentPoints: "CreateQuestionComponentPoints",
    CreateQuestionComponentTitle: "CreateQuestionComponentTitle",
    CreateQuestionComponentSave: "CreateQuestionComponentSave",
    CreateQuestionComponentCancel: "CreateQuestionComponentCancel",
    CreateQuestionComponentCreate: "CreateQuestionComponentCreate",

    NotFoundComponentTitle: "NotFoundComponentTitle",
    NotFoundComponentSubtitle: "NotFoundComponentSubtitle",

    AudioZoneComponentAudioUploaded: "AudioZoneComponentAudioUploaded",
    AudioZoneComponentResultTitle: "AudioZoneComponentResultTitle",
    AudioZoneComponentTitle: "AudioZoneComponentTitle",
    AudioZoneComponentDropHere: "AudioZoneComponentDropHere",
    AudioZoneComponentJustDrop: "AudioZoneComponentJustDrop",
    AudioZoneComponentLoadingAudio: "AudioZoneComponentLoadingAudio",

    TargetCircleComponentServiceTarget: "TargetCircleComponentServiceTarget",

    TableSatisfactionComponentTitleClient: "TableSatisfactionComponentTitleClient",
    TableSatisfactionComponentTitleOPType: "TableSatisfactionComponentTitleOPType",
    TableSatisfactionComponentTotalCalls: "TableSatisfactionComponentTotalCalls",
    TableSatisfactionComponentSentiments: "TableSatisfactionComponentSentiments",
    TableSatisfactionComponentDetails: "TableSatisfactionComponentDetails",
    //#endregion---------------------- Components ----------------------//
    
    //#region---------------------- Use Cases ----------------------//
    CheckAudioWithQuestionsUseCaseLoadingAudio: "CheckAudioWithQuestionsUseCaseLoadingAudio",
    CheckAudioWithQuestionsUseCaseUploadingAudio: "CheckAudioWithQuestionsUseCaseUploadingAudio",
    CheckAudioWithQuestionsUseCaseUploadedAudio: "CheckAudioWithQuestionsUseCaseUploadedAudio",
    CheckAudioWithQuestionsUseCaseSendingQuestions: "CheckAudioWithQuestionsUseCaseSendingQuestions",
    CheckAudioWithQuestionsUseCaseCreatingPetiton: "CheckAudioWithQuestionsUseCaseCreatingPetiton",
    CheckAudioWithQuestionsUseCaseErrorCreatedPetiton: "CheckAudioWithQuestionsUseCaseErrorCreatedPetiton",
    CheckAudioWithQuestionsUseCaseProcessingPetiton: "CheckAudioWithQuestionsUseCaseProcessingPetiton",
    CheckAudioWithQuestionsUseCaseErrorProcessingQuestions: "CheckAudioWithQuestionsUseCaseErrorProcessingQuestions",
    CheckAudioWithQuestionsUseCaseErrorUploadingAudio: "CheckAudioWithQuestionsUseCaseErrorUploadingAudio",
    CheckAudioWithQuestionsUseCaseProcessingQuestions: "CheckAudioWithQuestionsUseCaseProcessingQuestions",
    CheckAudioWithQuestionsUseCaseDone: "CheckAudioWithQuestionsUseCaseDone",
    //#endregion---------------------- Use Cases ----------------------//

    //#region---------------------- VALIDATORS ----------------------//
    ValidatorRequired: 'ValidatorRequired',
    ValidatorEmail: 'ValidatorEmail',
    ValidatorMinLength: 'ValidatorMinLength',
    ValidatorMaxLength: 'ValidatorMaxLength',
    ValidatorMinValue: 'ValidatorMinValue',
    ValidatorMaxValue: 'ValidatorMaxValue',
    ValidatorMustBeNumber: 'ValidatorMustBeNumber',
    ValidatorSpecialCharactersIsRequired: 'ValidatorSpecialCharactersIsRequired',
    ValidatorUpperCaseIsRequired: 'ValidatorUpperCaseIsRequired',
    ValidatorLowerCaseIsRequired: 'ValidatorLowerCaseIsRequired',
    ValidatorNumberIsRequired: 'ValidatorNumberIsRequired',
    ValidatorMustBeEqual: 'ValidatorMustBeEqual',
    ValidatorSpecialCharacterIsNotAllowed: 'ValidatorSpecialCharacterIsNotAllowed',
    ValidatorUpperCaseIsNotAllowed: 'ValidatorUpperCaseIsNotAllowed',
    ValidatorLowerCaseIsNotAllowed: 'ValidatorLowerCaseIsNotAllowed',
    ValidatorNumberIsNotAllowed: 'ValidatorNumberIsNotAllowed',
    ValidatorIsNotName: 'ValidatorIsNotName',
    ValidatorIsNotPhone: 'ValidatorIsNotPhone',
    ValidatorGreaterThan: 'ValidatorGreaterThan', //value
    ValidatorLessThan: 'ValidatorLessThan', //value
    ValidatorMustHavePartBeforeAt: 'ValidatorMustHavePartBeforeAt',
    ValidatorGreaterOrEqualThan: 'ValidatorGreaterOrEqualThan', //value
    ValidatorMinTime: "ValidatorMinTime", //value
    ValidatorMaxTime : "ValidatorMaxTime", //value    //#endregion---------------------- VALIDATORS ----------------------//

    //#region---------------------- UTILS ----------------------//
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    //#endregion---------------------- UTILS ----------------------//

    //#region---------------------- Others ----------------------//
    UnknownError: 'UnknownError',
    ErrorServerWrongPassword: "ErrorServerWrongPassword",
    ErrorHostInvalidUsernameOrPassword: "ErrorHostInvalidUsernameOrPassword",
    ErrorHostundefined: "ErrorHostundefined",
    //#endregion---------------------- Others ----------------------//

}

export default KeyWordLocalization;