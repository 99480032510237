import LocalizationEntity from "../../../../../domain/entities/LocalizationEntity";
import LocalizationList from "./dictionaries/LocalizationList";

const GetCurrentLanguageApiImpl = async (keyStorage: string): Promise<LocalizationEntity> => {
    //load json in host/'localization/en.json
    try{
        const response = await fetch(`/localization/en.json`);
        const data = await response.json();
        console.log('getted data', data);
        return {
            code: 'en',
            dictionary: data,
            image: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-400.png',
            name: 'English'	
        }
    }catch(e){
        console.log('error', e);
        return {
            code: 'en',
            dictionary: LocalizationList[0],
            image: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-400.png',
            name: 'English'
        }
    }
}

export default GetCurrentLanguageApiImpl;