import React from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import ProviderProps from '../../../domain/providers/ProviderProps';
import ModalsContextType from '../../../domain/providers/modal/ModalsContextType';
import ModalsProvider from '../../../domain/providers/modal/ModalsProvider';
import ModalsContext from './ModalsContext';
import ModalsComponent from './modals/ModalsComponent';


const _Actions: ModalsContextType = {
    isOpenModalCustom: false,
    openModalCustom: (size: "lg" | "xl" | "sm" | undefined, title: string | undefined, content: any, params?: {
        closeButton?: boolean;
        hideOnTap?: boolean;
    }) => { },
    closeModalCustom: () => { },
    customModal: undefined,

    //delete
    openModalDelete: (title: string, content: string, onConfirm: () => void) => { },
    closeModalDelete: () => { },
    isOpenModalDelete: false,
    deleteModal: undefined,

    //create
    openModalCreate: (title: string, name: string, content: string, onConfirm: () => void) => { },
    closeModalCreate: () => { },
    isOpenModalCreate: false,
    changeContent: (title: string, name: string, content: string, onConfirm: () => void) => { },
    createModal: undefined,

    //toast
    addToast: (message: string, type: "success" | "error" | "warning" | "alert" | "default", params: any, removeAll?: boolean | undefined) => { },
};
const _Provider: React.FC<ProviderProps> = ({ children }) => {
    //custom modal
    const [customModal, _setCustomModal] = React.useState<any | undefined>(undefined);
    const openModalCustom = (size: "lg" | "xl" | "sm" | undefined, title: string | undefined, content: any, params?: {
        closeButton?: boolean;
        hideOnTap?: boolean;
    }) => _setCustomModal({ content, title, size, params });
    const closeModalCustom = () => _setCustomModal(undefined);
    const isOpenModalCustom = customModal != undefined;

    //delete modal
    const [deleteModal, _setDeleteModal] = React.useState<any | undefined>(undefined);
    const openModalDelete = (title: string, content: string, onConfirm: () => void) => _setDeleteModal({ title, content, onConfirm });
    const closeModalDelete = () => _setDeleteModal(undefined);
    const isOpenModalDelete = deleteModal != undefined;

    //create modal
    const [createModal, _setCreateModal] = React.useState<any | undefined>(undefined);
    const openModalCreate = (title: string, name: string, content: string, onConfirm: () => void) => _setCreateModal({ title, name, content, onConfirm });
    const closeModalCreate = () => _setCreateModal(undefined);
    const changeContent = (title: string, name: string, content: string, onConfirm: () => void) => _setCreateModal({ title, name, content, onConfirm });
    const isOpenModalCreate = createModal != undefined;

    const addToast = (message: string, type: "success" | "error" | "warning" | "default" | "alert", params: any, removeAll?: boolean | undefined) => {
        if (removeAll) toast.dismiss();
        const paramsToast: ToastOptions<{}> = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        };
        if (type == "success") toast.success(message, paramsToast);
        if (type == "error") toast.error(message, paramsToast);
        if (type == "alert") {
            paramsToast.autoClose = false;
            paramsToast.hideProgressBar = true;
            paramsToast.theme = "colored";
            paramsToast.data = params;
            toast.error(message, paramsToast);
        }
        if (type == "warning") toast.warning(message, paramsToast);
        if (type == "default") toast(message, paramsToast);
    };

    //custom modal
    _Actions.isOpenModalCustom = isOpenModalCustom;
    _Actions.openModalCustom = openModalCustom;
    _Actions.closeModalCustom = closeModalCustom;
    _Actions.customModal = customModal;
    //delete modal
    _Actions.openModalDelete = openModalDelete;
    _Actions.closeModalDelete = closeModalDelete;
    _Actions.isOpenModalDelete = isOpenModalDelete;
    //create modal
    _Actions.openModalCreate = openModalCreate;
    _Actions.closeModalCreate = closeModalCreate;
    _Actions.isOpenModalCreate = isOpenModalCreate;
    _Actions.changeContent = changeContent;
    //toast
    _Actions.addToast = addToast;


    return <ModalsContext.Provider
        value={{
            isOpenModalCustom, openModalCustom, closeModalCustom, customModal,
            isOpenModalDelete, openModalDelete, closeModalDelete, deleteModal,
            isOpenModalCreate, openModalCreate, changeContent, closeModalCreate, createModal,
            addToast
        }}>
        <ModalsComponent>
            {children}
        </ModalsComponent>
    </ModalsContext.Provider>;
}


class ModalsProviderImpl implements ModalsProvider {
    public context = ModalsContext;
    public Provider = _Provider;
    Actions = _Actions
}

export default new ModalsProviderImpl();
