import { SentimentsOnDate } from "../../domain/repositories/DashboardRepository";
import DateDto from "./DateDto";

const fromJson = (json: any): SentimentsOnDate => {
    return {
        date: DateDto.fromServer(json.date),
        sentiments: {
            negative: json.total_negative_sentiments,
            neutral: json.total_neutral_sentiments,
            positive: json.total_positive_sentiments,
        }
    }
}

export default {
    fromJson,
}