import { injectable } from "inversify";
import QARepository from "../../../domain/repositories/QARepository";
import { Either, right, left } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/ErrorEntity";
import QAAgentEntity from "../../../domain/entities/QAAgentEntity";
import QAResultEntity from "../../../domain/entities/QAResultEntity";
import Testing from "../../../presentation/utils/Testing";
import QAQuestionEntity from "../../../domain/entities/QAQuestionEntity";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";


const fakeAgent: QAAgentEntity = {
    id: "123",
    name: "Test Agent",
    client: "Test Client",
    opType: "Test OpType",
    questions: Array.from({ length: 5 }, (_, i) => ({
        id: "123",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam condimentum arcu vel accumsan efficitur. Fusce quis purus ultricies, cursus nisl id, consectetur orci. Mauris posuere rhoncus nibh, in sollicitudin nibh faucibus a. Nulla a quam sollicitudin, consectetur dui a, pellentesque nisl. Pellentesque auctor augue nec tincidunt vulputate. Nunc in nisl id lorem varius lacinia. Quisque scelerisque varius varius. In justo sapien, consecte",
        points: 15,
        title: "Test Title",
        agentId: "1",
    })),
    clientId: "123",
    opTypeId: "123",
}

@injectable()
class QARepositoryFake implements QARepository {
    updateAgent = async (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => {
        await Testing.sleeper(1000);
        return right(agent);
    }
    deleteQuestionFromAgent = async (questionId: string): Promise<Either<ErrorEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    updateQuestionFromAgent = async (question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        await Testing.sleeper(1000);
        return right(question);
    }
    getAgentById = async (id: string): Promise<Either<ErrorEntity, QAAgentEntity>> => {
        await Testing.sleeper(1000);
        // return left({code: "notFound"});
        return right(fakeAgent);
    }
    addQuestionToAgent = async (agentId: string, question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        await Testing.sleeper(1000);
        return right(question);
    }
    uploadAudioForTranscription = async (audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>> => {
        await Testing.sleeper(1000);
        return right("https://samplelib.com/lib/preview/mp3/sample-3s.mp3");
    }
    getAudioResults = async (jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>> => {
        await Testing.sleeper(1000);
        return right({
            id: "123",
            audioUrl: "https://samplelib.com/lib/preview/mp3/sample-3s.mp33",
            responses: Array.from({ length: 5 }, (_, i) => ({
                response: {
                    points: 9,
                    short: "Yes",
                    justification: "The agent greeted the customer warmly and thanked them for their inquiry, which is a friendly and appropriate way to start the conversation.",
                },
                question: {
                    title: "Opening",
                    description: "Was the agent's greeting appropriate?",
                    points: 10,
                }
            }))
        })
    }
    createAgent = async (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => {
        await Testing.sleeper(1000);
        return right(agent);
    }
    searchAgents = async (query: string, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => {
        await Testing.sleeper(1000);
        const limitOfPages = 3;
        if (page > limitOfPages) {
            return right({
                total: limitOfPages * itemsPerPage,
                data: [],
                itemsPerPage,
                page,
                pages: limitOfPages,
            });
        }
        const arrayOfFakes = Array.from({ length: page == limitOfPages ? itemsPerPage / 2 : itemsPerPage }, (_, i) => fakeAgent);
        return right({
            total: (limitOfPages * itemsPerPage) - (itemsPerPage / 2),
            data: arrayOfFakes,
            itemsPerPage,
            page,
            pages: limitOfPages,
        });
    }
    deleteAgent = async (id: string): Promise<Either<ErrorEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
}

export default QARepositoryFake;