import { injectable } from "inversify";
import QARepository from "../../repositories/QARepository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/ErrorEntity";
import QAQuestionEntity from "../../entities/QAQuestionEntity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class CreateQuestionForAgentUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (agentId: string, question: QAQuestionEntity, ) : Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        return this.qaRepository.addQuestionToAgent(agentId, question);
    }
}

export const CreateQuestionForAgentUseCaseName = "CreateQuestionForAgentUseCase";