import di from "../../DependencyInjection";
import LocalizationProvider, { LocalizationProviderName } from "../../domain/providers/localization/LocalizationProvider";
import KeyWordLocalization from "./KeyWordLocalization";

const dayForGraphs = (date: Date): string => {
    const months = [KeyWordLocalization.january, KeyWordLocalization.february, KeyWordLocalization.march, KeyWordLocalization.april, KeyWordLocalization.may, KeyWordLocalization.june, KeyWordLocalization.july, KeyWordLocalization.august, KeyWordLocalization.september, KeyWordLocalization.october, KeyWordLocalization.november, KeyWordLocalization.december];
    const i18n = di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n;
    const day = date.getDate();
    const dd = day < 10 ? `0${day}` : day;
    const month = date.getMonth();
    const mm = i18n(months[month]);
    return `${dd} ${mm}`;
}

export default {
    dayForGraphs,
}