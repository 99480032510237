import QAAgentEntity from "../../domain/entities/QAAgentEntity";
import QAQuestionDto from "./QAQuestionDto";

const fromJSON = (json: any): QAAgentEntity => {
    return {
        client: json['client'] ?? '',
        id: json['id']?.toString() ?? '',
        name: json['name'] ?? '',
        opType: json['op_type'] ?? '',
        questions: json['questions'] != null ? json['questions'].map((item: any)=>QAQuestionDto.fromJSON(item)) : null,
        clientId: json['client_id']?.toString() ?? '',
        opTypeId: json['operation_id']?.toString() ?? '',

    }
}

export default {
    fromJSON,
}