import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import QAQuestionEntity from "../../../../domain/entities/QAQuestionEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAQuestionDto from "../../../dto/QAQuestionDto";

const UpdateQuestionApiImpl = async (agent: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
    console.log('update question api', agent);
    try {
        const relativeUrl = `/question/${agent.id}`;
        const body = QAQuestionDto.toJSON(agent);
        const response = await di.get<HostApi>(HostApiName).put(relativeUrl, body, { contentType: 'application/json' });
        console.log('response put question', response);
        return right(QAQuestionDto.fromJSON(response));
    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default UpdateQuestionApiImpl;