import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import QAResultEntity from "../../../../domain/entities/QAResultEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAResultDto from "../../../dto/QAResultDto";
import KeyWordLocalization from "../../../../presentation/utils/KeyWordLocalization";

const GetAudioResultsApiImpl = async (jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>> => {
    // const status = ["CREATED", "PROCESSING", "COMPLETED"];
    try {
        onCallBack?.(0, KeyWordLocalization.CheckAudioWithQuestionsUseCaseCreatingPetiton);
        const getAudioResults = async (jobId: string): Promise<QAResultEntity | string> => {
            const relativeUrl = "/job/jobs/" + jobId;
            const response = await di.get<HostApi>(HostApiName).get(relativeUrl, { contentType: "application/json" });
            if (response.job_status === "COMPLETED") {
                const re = QAResultDto.fromJSON(response);
                console.log("re", re);
                return re;
            } else return response.job_status;
        };

        //call every 5 seconds until get result type qa
        let result = await getAudioResults(jobId);
        let timeToWait = 5;
        while (typeof result === "string") {
            await new Promise((resolve) => setTimeout(resolve, timeToWait * 1000));
            result = await getAudioResults(jobId);
            if (result === "FAILED") {
                return left({ code: KeyWordLocalization.CheckAudioWithQuestionsUseCaseErrorProcessingQuestions });
            } else if (result === "CREATED") {
                onCallBack?.(10, KeyWordLocalization.CheckAudioWithQuestionsUseCaseCreatingPetiton);
            } else if (result === "PROCESSING") {
                timeToWait = 10;
                onCallBack?.(50, KeyWordLocalization.CheckAudioWithQuestionsUseCaseProcessingPetiton);
            }
        }
        return right(result as QAResultEntity);

    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default GetAudioResultsApiImpl;