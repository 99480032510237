import QAQuestionEntity from "../../domain/entities/QAQuestionEntity";

const fromJSON = (json: any): QAQuestionEntity => {
    return {
        id: json['id']?.toString() ?? '',
        points: json['points'] ?? 0,
        description: json['description'] ?? '',
        title: json['title'] ?? '',
        agentId: json['agent_id']?.toString() ?? '',
    }
}

const toJSON = (entity: QAQuestionEntity): any => {
    const parsed: { id?: number, points: number, description: string, title: string, agent_id: string } = {
        id: entity.id ? parseInt(entity.id) : undefined,
        points: entity.points,
        description: entity.description,
        title: entity.title,
        agent_id: entity.agentId,
    }
    if (parsed.id == null ) delete parsed.id;
    console.log('toJSON', parsed);
    return parsed;
}

export default {
    fromJSON,
    toJSON,
}