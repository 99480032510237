import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/ErrorEntity";
import SearchResultEntity from "../../../../domain/entities/SearchResultEntity";
import QAAgentEntity from "../../../../domain/entities/QAAgentEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAAgentDto from "../../../dto/QAAgentDto";

const SearchAgentsApiImpl = async (query: string, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => {
    try {
        let relativeUrl: string = `/agent/filter?page_number=${page}&page_size=${itemsPerPage}&keyword=${query??""}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        const parsed = response.content.map((item: any) => QAAgentDto.fromJSON(item));
        return right({
            data: parsed,
            itemsPerPage: itemsPerPage,
            page: page,
            pages: response.total_pages, //TODO fill it,
            total: response.total_elements //TODO fill it
        })
    } catch (e) {
        return left({ code: "Error" });
    }
}

export default SearchAgentsApiImpl;
