import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const GenerateAudioLinkApiImpl = async (audios: string[], agentId: string): Promise<Either<ErrorEntity, {
    url: string,
    jobID: string,
    audioName: string
}>> => {
    try {
        const relativeUrl = "/audio/generate-upload-urls";
        const body = {
            "audio_files": audios,
            "agent_id": agentId
        }
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, {
            contentType: "application/json"
        });
        console.log("generate audio link", {body, response});
        if(response.length == 0) {
            return left(ErrorEntityFromError(new Error("No audio files")));
        }

        return right({
            url: response[0].url,
            jobID: response[0].job_id,
            audioName: response[0].audio_file
        });

    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default GenerateAudioLinkApiImpl;