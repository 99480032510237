import "./AuthLayoutComponent.scss";
import { FC } from "react";
import AuthLayoutComponentProps from "./AuthLayoutComponentProps";
import AuthInfoComponent from "../../components/authInfo/AuthInfoComponent";

const AuthLayoutComponent: FC<AuthLayoutComponentProps> = ({ children }) => {
    return <div className="auth_layout_component">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 bg_white d-flex align-items-center">
                    <div className="form_container">
                        {children}
                    </div>
                </div>
                <div className="col-lg-8 bg_select">
                    <div className="logo_container">
                        <img src="./assets/logo/short_yellow.png" alt="" />
                    </div>
                    <AuthInfoComponent />
                </div>
            </div>
        </div>
    </div >
}

export default AuthLayoutComponent;