import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../entities/ErrorEntity";
import UserEntity from "../entities/UserEntity";

export default interface AuthRepository {
    loginWithEmailAndPassword(email: string, password: string): Promise<Either<ErrorEntity, UserEntity>>;
    loginWithGoogle(): Promise<Either<ErrorEntity, UserEntity>>;
    loginWithFacebook(): Promise<Either<ErrorEntity, UserEntity>>;
    loginWithApple(): Promise<Either<ErrorEntity, UserEntity>>;
    getCurrentUser(): Promise<UserEntity | null>;
    logout(): Promise<Either<ErrorEntity, void>>;
}

export const AuthRepositoryName = "AuthRepository";