import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const DeleteQuestionApiImpl = async (questionId: string): Promise<Either<ErrorEntity, void>> =>{
    try {
        const relativeUrl = `/question/${questionId}`;
        const response = await di.get<HostApi>(HostApiName).remove(relativeUrl);
        console.log('response delete question', response);
        return right(undefined);
    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default DeleteQuestionApiImpl;