import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const DeleteAgentApiImpl = async (agentId: string): Promise<Either<ErrorEntity, void>> =>{
    try {
        const relativeUrl = `/agent/${agentId}`;
        const response = await di.get<HostApi>(HostApiName).remove(relativeUrl);
        return right(undefined);
    } catch (error) {
        return left(ErrorEntityFromError(error));
    }
}

export default DeleteAgentApiImpl;