import { FC, useContext } from "react";
import "./TargetCircleComponent.scss";
import TargetCircleComponentProps from "./TargetCircleComponentProps";
import { Doughnut } from "react-chartjs-2";
import LocalizationContext from "../../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";

const TargetCircleComponent: FC<TargetCircleComponentProps> = ({ target, current, increment }) => {
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const loadDataSet = (): any => {
        const datasets: any[] = [];
        const getGradientColor = (value: number): string => {
            if (value < 0 || value > 100) {
                throw new Error("El valor debe estar entre 1 y 100.");
            }

            // Definimos los colores clave (en formato RGB)
            const colors = [
                { value: 0, color: [255, 0, 0] }, // Rojo
                { value: 20, color: [255, 255, 0] }, // Amarillo
                { value: 50, color: [255, 165, 0] }, // Naranja
                { value: 70, color: [173, 216, 230] }, // Azul claro
                { value: 90, color: [0, 0, 139] }, // Azul oscuro
                { value: 100, color: [0, 0, 139] } // Azul oscuro
            ];

            // Encontramos el rango en el que se encuentra el valor
            let start = colors[0];
            let end = colors[0];

            for (let i = 1; i < colors.length; i++) {
                if (value <= colors[i].value) {
                    end = colors[i];
                    break;
                }
                start = colors[i];
            }

            // Interpolamos los colores
            const range = end.value - start.value;
            const rangeValue = value - start.value;
            const ratio = rangeValue / range;

            const interpolatedColor = start.color.map((startChannel, index) =>
                Math.round(startChannel + ratio * (end.color[index] - startChannel))
            );

            //convert from rgb to hex
            const hexColor = "#" + interpolatedColor.map((channel) => {
                const hex = channel.toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            }).join("");

            // Convertimos el color a formato hexadecimal
            return hexColor;
        }
        const getColor = (value: number): string => {
            const minLimint = 30;
            const maxLimit = 70;
            const diff = maxLimit - minLimint;
            const fraction = (100 - diff) / 100;
            if (value > minLimint && value < maxLimit) return "#ffffff";
            if (value <= minLimint) {
                const equivalent = ((value / fraction) + 50);
                if (equivalent <= current) return getGradientColor(equivalent);
                // if (equivalent <= current) return getGradientColor(value);
                else return "#cdcdcd";
            } if (value >= maxLimit) {
                const equivalent = (value - maxLimit) / fraction;
                if (equivalent < ((current))) return getGradientColor(equivalent);
                else return "#cdcdcd";
            }
            else return "#cdcdcd";
        }
        for (let i = 0; i < 100; i++) {
            const dataset = {
                value: 1,
                borderColor: getColor(i),
            }
            datasets.push(dataset);
        }
        const parsed = {
            label: "Label",
            data: datasets.map(d => d.value),
            backgroundColor: datasets.map(d => d.borderColor + "aa"),
            hoverOffset: 4
        }
        return [parsed];
    }

    return <div className="target_circle_component">
        <Doughnut options={{
            cutout: '80%',
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            }
        }} data={{
            datasets: loadDataSet()
        }} />
        <div className="target_info">
            <h1>{current}%</h1>
            <span className="service_target">{i18n(KeyWordLocalization.TargetCircleComponentServiceTarget)} <strong>{target}</strong>%</span>
            <div className={`increment_container ${increment > 0 && 'increment'} ${increment < 0 && 'decrement'}`}>
                <span className="material-symbols-outlined">
                    {increment > 0 && 'arrow_drop_up'}
                    {increment < 0 && 'arrow_drop_down'}
                </span>
                <span>{increment}%</span>
            </div>
        </div>
    </div>
}

export default TargetCircleComponent;