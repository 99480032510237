import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity, { ErrorEntityFromError } from "../../../../domain/entities/ErrorEntity";
import UserEntity from "../../../../domain/entities/UserEntity";
import di from "../../../../DependencyInjection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import UserDto from "../../../dto/UserDto";
import KeyWordLocalization from "../../../../presentation/utils/KeyWordLocalization";

const LoginWithUserAndPasswordApiImpl = async (user: string, password: string): Promise<Either<ErrorEntity, UserEntity>> => {
    try {
        const relativeRoute = "/token";
        const response = await di.get<HostApi>(HostApiName).post(relativeRoute, `username=${user}&password=${password}`);
        if(response.detail == "Invalid username or password") return left({code: KeyWordLocalization.ErrorHostInvalidUsernameOrPassword});
        di.get<HostApi>(HostApiName).setToken(response.access_token);
        const userParsed = UserDto.fromJSON(response.user_info);
        return right(userParsed);
    } catch (e) {
        return left(ErrorEntityFromError(e));
    }
}

export default LoginWithUserAndPasswordApiImpl;