import { injectable } from "inversify";
import AuthRepository from "../../repositories/AuthRepository";
import ErrorEntity from "../../entities/ErrorEntity";
import UserEntity from "../../entities/UserEntity";
import { Either, isRight } from "fp-ts/lib/Either";
import UserProvider from "../../providers/user/UserProvider";

interface _Props {
    userProvider: UserProvider;
    authRepository: AuthRepository;
}

@injectable()
export default class LoginWithEmailAndPasswordUseCase {
    private userProvider: UserProvider;
    private authRepository: AuthRepository;
    constructor(props: _Props) {
        this.userProvider = props.userProvider;
        this.authRepository = props.authRepository;
    }
    public call = async (email: string, password: string) : Promise<Either<ErrorEntity, UserEntity>> => {
        const result = await this.authRepository.loginWithEmailAndPassword(email, password);
        if (isRight(result)) {
            this.userProvider.Actions.setUser(result.right);
        }else {
            this.userProvider.Actions.setUser(null);
        }
        return result;
    }
}

export const LoginWithEmailAndPasswordUseCaseName = "LoginWithEmailAndPasswordUseCase";